const axios = require("axios");
const baseUrl = require("../../global");
const { getToken } = require("../services/localStorageFunc");
import { config } from "./configHeader";

export async function createCategory(category) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.post(`${baseUrl.default}/category`, category, config);
}

export async function getCategory() {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.get(`${baseUrl.default}/category`, config);
}

export async function updateCategory(category) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.put(`${baseUrl.default}/category`, category, config);
}

export async function deleteCategory(category) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.delete(
    `${baseUrl.default}/category/${category.id}`,
    config
  );
}
