const axios = require("axios");
const baseUrl = require("../../global");
import { config } from "./configHeader";
const { getToken } = require("./localStorageFunc");

export async function createCompany(company) {
  return await axios.post(`${baseUrl.default}/newcompany`, company, config);
}

export async function getDatasAllCompany(nameUrl) {
  return await axios.post(`${baseUrl.default}/allDatas`, nameUrl, config);
}

export async function getCompany() {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.get(`${baseUrl.default}/company`, config);
}

export async function updateCompany(company) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.put(`${baseUrl.default}/company`, company, config);
}

export async function getQrcode() {
  return await axios.post(
    `${baseUrl.default}/newcompany/qrcode`,
    { valueQrcode: 25.9 },
    config
  );
}

export async function getQrcodeStatus(data) {
  return await axios.post(
    `${baseUrl.default}/newcompany/qrcode/status`,
    data,
    config
  );
}
