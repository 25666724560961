<template>
  <!-- Sidebar -->
  <div id="sidebar-wrapper">
    <ul class="sidebar-nav">
      <li class="sidebar-avatar">
        <div class="avatar"></div>
      </li>

      <a :href="`/menu/${company.name_url}`" target="_blank" class="meumenu">
        <div class="container text-center py-3 divlinkmenu">MEU MENU</div>
      </a>

      <li>
        <router-link to="/home"
          ><i class="icofont-dashboard-web"></i> Dashboard</router-link
        >
      </li>
      <li>
        <router-link to="/products"
          ><i class="icofont-basket"></i> Produtos
        </router-link>
      </li>
      <li>
        <router-link to="/category"
          ><i class="icofont-tag"></i> Categorias
        </router-link>
      </li>
      <li>
        <router-link to="/additional"
          ><i class="icofont-checked"></i> Adicionais
        </router-link>
      </li>
      <li>
        <router-link to="/company"
          ><i class="icofont-bars"></i> Empresa
        </router-link>
      </li>
      <li>
        <router-link to="/hours"
          ><i class="icofont-clock-time"></i> Horários
        </router-link>
      </li>
      <!-- <li>
                <a href="#" v-on:click.prevent v-b-toggle.collapse-4><i class="icofont-user-suited"></i> Lorem ipsum
                    <span class="caret float-right"><i class="icofont-caret-down"></i></span></a>
                <b-collapse id="collapse-4">
                    <ul id="collapse-4">
                        <li>
                            <router-link to=''>Lorem ipsum</router-link>
                        </li>
                        <li>
                            <router-link to=''>Lorem ipsum</router-link>
                        </li>
                    </ul>
                </b-collapse>
            </li> -->
      <li>
        <a href="#" @click="logoutUser"
          ><i class="icofont-logout" id="logout"></i> Sair</a
        >
      </li>
    </ul>
  </div>
  <!-- /#sidebar-wrapper -->
</template>

<script>
import { getCompany } from "../services/localStorageFunc";
import { logout } from "../services/login";
export default {
  name: "SidebarComponent",
  data() {
    return {
      company: {
        name_url: "",
      },
    };
  },
  methods: {
    logoutUser() {
      logout();
      this.$router.push({ path: "/login" });
    },
  },
  async mounted() {
    getCompany().then((company) => {
      if (company.length > 0) {
        this.company = company[0];
      }
    });
  },
};
</script>

<style scoped>
.meumenu {
  color: white;
  font-weight: 700;
  text-decoration: none;
}

.divlinkmenu:hover {
  background-color: #fafafa;
  color: #000;
}

li {
  list-style: none;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #3d405b;
  color: white;

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
  color: white;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 30px;
  color: white;
}

.sidebar-nav li ul li {
  text-indent: 20px;
  line-height: 15px;
  color: white;
}

.sidebar-avatar {
  padding-bottom: 10px;
  border-bottom: 1px solid #f9f9f9;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: white;

  padding: 10px 0;
  font-size: 19px;
  text-align: left;
}

.sidebar-nav li ul li a {
  font-size: 18px;
  color: white;
}

.sidebar-nav li a:hover {
  background-color: #f5f5f5;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #000;
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

.avatar {
  width: auto;
  height: 160px;
  margin: 15px auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #3d405b;
  background-image: url("../assets/eu.png");
}

.avatar-name {
  text-align: center;
  font-size: 18px;
  margin-left: -25px;
}

#logout {
  color: red;
}
</style>
