const axios = require("axios");
const baseUrl = require("../../global");
import { config } from "./configHeader";
const { getToken } = require("./localStorageFunc");

export async function createHours(hours) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.post(`${baseUrl.default}/hours`, hours, config);
}

export async function getHours() {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.get(`${baseUrl.default}/hours`, config);
}

export async function updateHours(hours) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.put(`${baseUrl.default}/hours`, hours, config);
}
