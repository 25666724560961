export async function setToken(token, company) {
  localStorage.setItem("token", token);
  localStorage.setItem("company", company);
  return;
}

export async function getToken() {
  return localStorage.getItem("token");
}

export async function getCompany() {
  return JSON.parse(localStorage.getItem("company"));
}

export async function deleteToken() {
  localStorage.removeItem("company");
  localStorage.removeItem("token");
  return;
}
