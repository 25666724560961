<template>
  <div class="container">
    <div class="text-center pt-2">
      <h2 style="color: black">Horários</h2>
    </div>

    <div class="container">
      <b-alert v-model="showAlertUpdateHours" variant="success" dismissible>
        Atualizado com sucesso
      </b-alert>
    </div>

    <div class="text-right pb-1">
      <button class="btn btn-success" @click="save()">
        <strong><i class="fa fa-save"></i> Salvar</strong>
      </button>
    </div>

    <div class="table-responsive-sm">
      <table class="table table-bordered table-hover">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Dia</th>
            <th scope="col" class="bg-light">
              <div>1° horário</div>
              <div class="container">
                <div class="row">
                  <div class="col-md">Entrada</div>
                  <div class="col-md">Saída</div>
                </div>
              </div>
            </th>
            <th scope="col" class="bg-secondary">
              <div>2° horário</div>
              <div class="container">
                <div class="row">
                  <div class="col-md">Entrada</div>
                  <div class="col-md">Saída</div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr>
              <td colspan="5" align="center">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </td>
            </tr> -->
          <tr>
            <td>Segunda</td>
            <td class="bg-light">
              <div class="row">
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.segunda.primeiro.entrada"
                    required
                  />
                </div>
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.segunda.primeiro.saida"
                    required
                  />
                </div>
              </div>
            </td>
            <td class="bg-secondary">
              <div class="row">
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.segunda.segundo.entrada"
                    required
                  />
                </div>
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.segunda.segundo.saida"
                    required
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Terça</td>
            <td class="bg-light">
              <div class="row">
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.terca.primeiro.entrada"
                    required
                  />
                </div>
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.terca.primeiro.saida"
                    required
                  />
                </div>
              </div>
            </td>
            <td class="bg-secondary">
              <div class="row">
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.terca.segundo.entrada"
                    required
                  />
                </div>
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.terca.segundo.saida"
                    required
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Quarta</td>
            <td class="bg-light">
              <div class="row">
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.quarta.primeiro.entrada"
                    required
                  />
                </div>
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.quarta.primeiro.saida"
                    required
                  />
                </div>
              </div>
            </td>
            <td class="bg-secondary">
              <div class="row">
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.quarta.segundo.entrada"
                    required
                  />
                </div>
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.quarta.segundo.saida"
                    required
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Quinta</td>
            <td class="bg-light">
              <div class="row">
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.quinta.primeiro.entrada"
                    required
                  />
                </div>
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.quinta.primeiro.saida"
                    required
                  />
                </div>
              </div>
            </td>
            <td class="bg-secondary">
              <div class="row">
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.quinta.segundo.entrada"
                    required
                  />
                </div>
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.quinta.segundo.saida"
                    required
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Sexta</td>
            <td class="bg-light">
              <div class="row">
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.sexta.primeiro.entrada"
                    required
                  />
                </div>
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.sexta.primeiro.saida"
                    required
                  />
                </div>
              </div>
            </td>
            <td class="bg-secondary">
              <div class="row">
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.sexta.segundo.entrada"
                    required
                  />
                </div>
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.sexta.segundo.saida"
                    required
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Sabado</td>
            <td class="bg-light">
              <div class="row">
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.sabado.primeiro.entrada"
                    required
                  />
                </div>
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.sabado.primeiro.saida"
                    required
                  />
                </div>
              </div>
            </td>
            <td class="bg-secondary">
              <div class="row">
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.sabado.segundo.entrada"
                    required
                  />
                </div>
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.sabado.segundo.saida"
                    required
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>Domingo</td>
            <td class="bg-light">
              <div class="row">
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.domingo.primeiro.entrada"
                    required
                  />
                </div>
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.domingo.primeiro.saida"
                    required
                  />
                </div>
              </div>
            </td>
            <td class="bg-secondary">
              <div class="row">
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.domingo.segundo.entrada"
                    required
                  />
                </div>
                <div class="col-md">
                  <input
                    type="time"
                    class="form-control"
                    v-model="horarios.domingo.segundo.saida"
                    required
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  </div>
</template>

<script>
import { getHours, createHours, updateHours } from "../services/Hours";

export default {
  name: "FormHorarios",
  data() {
    return {
      loading: true,
      acao: "Save",
      showAlertUpdateHours: false,
      cnpjcpf: localStorage.getItem("cnpjcpf"),
      id: "",
      horarios: {
        segunda: {
          primeiro: {
            entrada: "",
            saida: "",
          },
          segundo: {
            entrada: "",
            saida: "",
          },
        },
        terca: {
          primeiro: {
            entrada: "",
            saida: "",
          },
          segundo: {
            entrada: "",
            saida: "",
          },
        },
        quarta: {
          primeiro: {
            entrada: "",
            saida: "",
          },
          segundo: {
            entrada: "",
            saida: "",
          },
        },
        quinta: {
          primeiro: {
            entrada: "",
            saida: "",
          },
          segundo: {
            entrada: "",
            saida: "",
          },
        },
        sexta: {
          primeiro: {
            entrada: "",
            saida: "",
          },
          segundo: {
            entrada: "",
            saida: "",
          },
        },
        sabado: {
          primeiro: {
            entrada: "",
            saida: "",
          },
          segundo: {
            entrada: "",
            saida: "",
          },
        },
        domingo: {
          primeiro: {
            entrada: "",
            saida: "",
          },
          segundo: {
            entrada: "",
            saida: "",
          },
        },
      },
    };
  },
  methods: {
    async save() {
      let isSave = this.horarios.id
        ? await updateHours(this.horarios)
        : await createHours(this.horarios);

      if (isSave.status == 201) {
        this.showAlertUpdateHours = true;
      }
    },

    async getHours() {
      let data = await getHours();
      if (data.data) {
        this.horarios = data.data;
      }
    },
  },
  mounted() {
    this.getHours();
  },
};
</script>

<style scope>
.bg-light {
  background-color: #e9e3e6 !important;
  color: black !important;
}

.bg-secondary {
  background-color: #c3baba !important;
  color: black !important;
}
</style>
