<template>
  <!-- Footer Section Start -->
  <footer id="footer" class="footer-area section-padding p-0 text-center">
    <div id="copyright">
      <div class="copyright-content">
        Copyright © {{ year }} ZAPINFOOD todos os direitos reservados. <br />
        CNPJ 37.482.314/0001-88 Desenvolvido por
        <a href="https://grendeltecnologia.com.br/" target="blank"
          ><strong>Grendel Tecnologia</strong></a
        >
      </div>
    </div>
  </footer>
  <!-- Footer Section End -->
</template>

<script>
export default {
  name: "FooterComponent",
  data: () => {
    return {
      year: 0,
    };
  },
  mounted() {
    this.year = new Date().getFullYear();
  },
};
</script>

<style scoped>
footer {
  float: center;
  width: 100%;
  background-color: #fff;
  padding: 10px;
}

footer p {
  margin-bottom: 0;
  padding: 10px;
  text-align: center;
}
</style>
