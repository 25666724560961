<template>
  <div>
    <Lp />
  </div>
</template>

<script>
import Lp from "../components/Lp";

export default {
  name: "landing-page",
  components: {
    Lp,
  },
};
</script>
