const axios = require("axios");
const baseUrl = require("../../global");
const { deleteToken, getToken } = require("../services/localStorageFunc");
import { config } from "./configHeader";

export async function login(auth) {
  return await axios.post(`${baseUrl.default}/auth`, { ...auth }, config);
}

export async function logout() {
  return deleteToken();
}

export async function validToken() {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.get(`${baseUrl.default}/validtoken`, config);
}
