<template>
  <div id="wrapper" v-bind:class="toggle">
    <Sidebar />
    <div id="page-content-wrapper">
      <!-- /#page-topbar -->
      <Topbar @toggle="toggleMenu()" />
      <!-- /#page-topbar -->
      <CompanyComponent />
    </div>
  </div>
</template>

<script>
import CompanyComponent from "../components/CompanyView";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";

export default {
  name: "CompanyView",
  components: {
    Sidebar,
    Topbar,
    CompanyComponent,
  },
  data() {
    return {
      toggle: "",
      toggleBool: false,
    };
  },
  methods: {
    toggleMenu() {
      this.toggleBool = !this.toggleBool;
      if (this.toggleBool == true) {
        this.toggle = "toggled";
      } else {
        this.toggle = "";
      }
    },
  },
};
</script>

<style scoped>
#msgError {
  color: red;
  font-weight: bold;
}

.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  vertical-align: middle;
  text-align: center;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-align: center;
}

.table tbody tr {
  border-bottom: 1px solid #f6f6f6;
}

body {
  overflow-x: hidden;
  background-color: #ecf0fa;
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #3d405b;
  color: white;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

.caret {
  margin-right: 10px;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

.dashboard {
  padding: 30px !important;
}

.card {
  margin-bottom: 30px;
  border: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  border: none !important;
}

.card .card-body .media-body {
  text-align: left;
}

.card .media i {
  font-size: 60px;
}

@media (max-width: 768px) {
  .dashboard {
    padding: 20px !important;
  }

  .card {
    margin-bottom: 15px;
  }
}
</style>
