const axios = require("axios");
const baseUrl = require("../../global");
const { getToken } = require("./localStorageFunc");
import { config } from "./configHeader";

export async function createAdditional(Additional) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.post(`${baseUrl.default}/additional`, Additional, config);
}

export async function getAdditional() {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.get(`${baseUrl.default}/additional`, config);
}

export async function updateAdditional(Additional) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.put(`${baseUrl.default}/additional`, Additional, config);
}

export async function deleteAdditional(Additional) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.delete(
    `${baseUrl.default}/additional/${Additional.id}`,
    config
  );
}
