const axios = require("axios");
const baseUrl = require("../../global");
import { config } from "./configHeader";
const { getToken } = require("./localStorageFunc");

export async function createDelivery(delivery) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.post(`${baseUrl.default}/delivery`, delivery, config);
}

export async function getDelivery() {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.get(`${baseUrl.default}/delivery`, config);
}

export async function updateDelivery(delivery) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.put(`${baseUrl.default}/delivery`, delivery, config);
}
