<template>
  <div id="wrapper" v-bind:class="toggle">
    <!-- Sidebar -->
    <Sidebar />
    <!-- /#sidebar-wrapper -->

    <!-- Page Content -->
    <div id="page-content-wrapper">
      <!-- /#page-topbar -->
      <Topbar @toggle="toggleMenu()" />
      <!-- /#page-topbar -->
      <div class="container-fluid dashboard">
        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <div class="card">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body">
                    <h4>{{ allProducts }}</h4>
                    <span>Produtos</span>
                  </div>
                  <div class="text-center">
                    <i class="icofont-basket"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="card">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body">
                    <h4>{{ allCategory }}</h4>
                    <span>Categorias</span>
                  </div>
                  <div class="text-center">
                    <i class="icofont-tag"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="card">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body">
                    <h4>{{ allAdditionals }}</h4>
                    <span>Adicionais</span>
                  </div>
                  <div class="text-center">
                    <i class="icofont-checked"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6">
            <div class="card">
              <div class="card-body">
                <div class="media align-items-center">
                  <div class="media-body">
                    <h4>{{ allOrders.length }}</h4>
                    <span>Pedidos</span>
                  </div>
                  <div class="text-center">
                    <i class="icofont-cart"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-light">
                  <tr>
                    <th>Cliente</th>
                    <th>Telefone</th>
                    <th>Itens</th>
                    <th>Status</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="order of allOrders" :key="order.id">
                    <td>{{ order.client.name }}</td>
                    <td>
                      <a
                        :href="`https://api.whatsapp.com/send?phone=${order.client.phone}`"
                        target="_blank"
                        >{{ order.client.phone }}</a
                      >
                    </td>
                    <td>{{ order.itens.length }}</td>
                    <td
                      :class="
                        order.status == 1
                          ? 'Aberto'
                          : order.status == 2
                          ? 'Preparando'
                          : 'Finalizado'
                      "
                    >
                      {{
                        order.status == 1
                          ? "Aberto"
                          : order.status == 2
                          ? "Preparando"
                          : "Finalizado"
                      }}
                    </td>
                    <td>
                      <div role="group">
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          @click="setViewOrder(order)"
                        >
                          <i class="fa fa-edit"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- /#page-footer -->
      <Footer />
      <!-- /#page-footer -->
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-center" id="exampleModalLabel">
              Visualizar Pedido
            </h5>
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-close"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="container text-left">
              <div id="print">
                <em><strong>Nome: </strong> {{ viewOrder.client.name }}</em
                ><br />
                <em>
                  <strong>Telefone: </strong>
                  <a
                    :href="`https://api.whatsapp.com/send?phone=${viewOrder.client.phone}`"
                    target="_blank"
                    >{{ viewOrder.client.phone }}</a
                  > </em
                ><br />
                <p>
                  <strong>Endereço: </strong>
                  {{ viewOrder.client.address.street }} Nº
                  {{ viewOrder.client.address.number }} -
                  {{ viewOrder.client.address.district }}
                  {{ viewOrder.client.address.city }}
                </p>
                <p><strong>Items:</strong></p>
                <!-- <p>{{ viewOrder.itens }}</p> -->
                <div
                  v-for="(item, index) of viewOrder.itens"
                  :key="index"
                  class="row"
                >
                  <div class="col-md">
                    <div class="card-body">
                      <div
                        class="row d-flex justify-content-between align-items-left"
                      >
                        <div class="col-md">
                          <p class="lead fw-normal mb-2">
                            {{ item.qtde }} - {{ item.product.name }} R$
                            {{ item.qtde * item.product.price }}
                          </p>
                          <p>
                            <span class="text-muted">{{
                              item.product.description
                            }}</span>
                          </p>
                        </div>
                      </div>

                      <div
                        class="d-flex justify-content-between align-items-text-left p-1 pt-2"
                        v-if="item.product.additional.length > 0"
                      >
                        <em class="lead fw-normal mb-2">Adicionais</em>
                      </div>

                      <ul class="list-inline pt-1">
                        <li
                          class="list-inline-item"
                          v-for="additional of item.product.additional"
                          :key="additional.id"
                        >
                          <strong
                            >{{ additional.name }} R$ {{ additional.price }}
                          </strong>
                        </li>
                      </ul>
                    </div>
                    <ul class="list-group mb-3">
                      <li
                        class="list-group-item d-flex justify-content-between"
                      >
                        <span
                          >Total (BRL)<br /><small class="text-muted"
                            >Tx. Entrega R$
                            {{ viewOrder.client.distance.toFixed(2) }}</small
                          >
                          <br />
                          <small class="text-muted"
                            >Pagamento: {{ viewOrder.client.payment }}</small
                          ><br />
                          <small
                            class="text-muted"
                            v-if="viewOrder.client.payment === 'Dinheiro'"
                            >Troco p/: {{ viewOrder.client.thing }}</small
                          >
                        </span>

                        <strong
                          >R$
                          {{
                            (
                              item.product.price * item.qtde +
                              sumAddtionalsCart(item.additional, item.qtde) +
                              viewOrder.client.distance
                            ).toFixed(2)
                          }}</strong
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card-footer text-center">
                <div class="row">
                  <div class="col-md">
                    <button
                      class="btn btn-warning"
                      v-if="viewOrder.status == 1"
                      @click="updateOrder(viewOrder, 2)"
                    >
                      <strong>Aceitar</strong>
                    </button>
                    <button
                      class="btn btn-success"
                      v-if="viewOrder.status == 2"
                      @click="updateOrder(viewOrder, 3)"
                    >
                      <strong>Finalizado</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>

            <button class="btn btn-secondary" @click="printOrder()">
              Imprimir
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { getAdditional } from "../services/Additional";
import { getCategory } from "../services/Category";
import { getOrders, updateOrder } from "../services/Orders";
import { getProduct } from "../services/Product";

export default {
  name: "HomeView",
  components: {
    Sidebar,
    Topbar,
    Footer,
  },
  data() {
    return {
      toggle: "",
      toggleBool: false,
      allProducts: 0,
      allCategory: 0,
      allAdditionals: 0,
      allOrders: [],
      orderOpen: [],
      viewOrder: {
        number: 0,
        client: {
          name: "",
          phone: "",
          address: {
            street: "",
            zipCode: "",
            district: "",
            number: "",
            city: "",
          },
        },
      },
      perPage: 10,
      currentPage: 1,
    };
  },
  methods: {
    toggleMenu() {
      this.toggleBool = !this.toggleBool;
      if (this.toggleBool == true) {
        this.toggle = "toggled";
      } else {
        this.toggle = "";
      }
    },
    setViewOrder(order) {
      document.getElementById("exampleModal").style.display = "block";
      document.getElementById("exampleModal").classList.add("show");

      this.viewOrder = order;
    },
    sumAddtionalsCart(adds, qtde) {
      let sum = 0;
      for (let add of adds) {
        sum += add.price;
      }
      return sum * qtde;
    },
    printOrder() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("print").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
                <html>
                <head>
                    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.1.3/dist/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">

                    ${stylesHtml}
                </head>
                <body>
                    ${prtHtml}
                    
                </body>
                
                </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    async getProducts() {
      let products = await getProduct();
      this.allProducts = products.data.data.length;
    },

    async getCategorys() {
      let categorys = await getCategory();
      this.allCategory = categorys.data.data.length;
    },

    async getAdditionals() {
      let Additionals = await getAdditional();
      this.allAdditionals = Additionals.data.data.length;
    },
    async getOrders() {
      let Orders = await getOrders();
      this.allOrders = Orders.data;
      this.orderOpen = this.allOrders.filter((e) => e.status != 3);
    },
    async updateOrder(order, status) {
      order.status = status;
      await updateOrder({ ...order });
      this.getOrders();
      this.$bvModal.hide("modal-view-order");
    },
  },
  mounted() {
    this.getProducts();
    this.getCategorys();
    this.getAdditionals();
    this.getOrders();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
};
</script>

<style scoped>
.text-left {
  text-align: left !important;
}
.Aberto {
  background-color: yellow;
  font-weight: 700;
}

.Preparando {
  background-color: blue;
  color: #e9ecef;
  font-weight: 700;
}

.Finalizado {
  background-color: rgb(119, 243, 119);
  color: black;
}

.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  vertical-align: middle;
  text-align: center;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-align: center;
}

.table tbody tr {
  border-bottom: 1px solid #f6f6f6;
}

body {
  overflow-x: hidden;
  background-color: #ecf0fa;
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #3d405b;
  color: white;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

.caret {
  margin-right: 10px;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

.dashboard {
  padding: 30px !important;
}

.card {
  margin-bottom: 30px;
  border: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  border: none !important;
}

.card .card-body .media-body {
  text-align: left;
}

.card .media i {
  font-size: 60px;
}

@media (max-width: 768px) {
  .dashboard {
    padding: 20px !important;
  }

  .card {
    margin-bottom: 15px;
  }
}
</style>
