<template>
  <div id="wrapper" v-bind:class="toggle">
    <!-- Sidebar -->
    <Sidebar />
    <!-- /#sidebar-wrapper -->

    <!-- Page Content -->
    <div id="page-content-wrapper">
      <!-- /#page-topbar -->
      <Topbar @toggle="toggleMenu()" />
      <!-- /#page-topbar -->
      <div class="container-fluid dashboard">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <div class="header-top">
                  <h5 class="m-0">CATEGORIAS</h5>
                </div>
              </div>
              <div class="card-body">
                <div class="text-right p-2">
                  <button
                    class="btn btn-primary"
                    v-b-modal.modalCategory
                    @click="openModalNew"
                  >
                    Adicionar
                  </button>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <thead class="thead-light">
                      <tr>
                        <th>Nome</th>
                        <th>Descrição</th>
                        <th>Status</th>
                        <th>Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="categorys.length == 0">
                        <td colspan="4">Sem dados</td>
                      </tr>
                      <tr v-for="category of categorys" :key="category.id">
                        <td>{{ category.name }}</td>
                        <td>{{ category.description }}</td>
                        <td>{{ category.status ? "Ativo" : "Inativo" }}</td>
                        <td>
                          <div role="group">
                            <button
                              class="btn btn-secondary mx-1 my-1"
                              title="Editar"
                              v-b-modal.modalCategory
                              @click="openModalEdit(category)"
                            >
                              <i class="icofont-pencil-alt-2"></i>
                            </button>
                            <button
                              class="btn btn-danger mx-1 my-1"
                              title="Excluir"
                              @click="deleteCategory(category)"
                            >
                              <i class="icofont-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /#page-footer -->
      <Footer />
      <!-- /#page-footer -->
    </div>
    <!-- /#page-content-wrapper -->

    <b-modal
      id="modalCategory"
      title="Adicionar categoria"
      hide-footer
      size="lg"
    >
      <form>
        <div class="form">
          <div class="form-group col-md">
            <label for="inputEmail4">Nome</label>
            <input
              type="text"
              class="form-control"
              id="inputEmail4"
              placeholder="Nome"
              v-model="category.name"
            />
          </div>
          <div class="form-group col-md">
            <label for="inputPassword4">Descrição</label>
            <input
              type="text"
              class="form-control"
              id="inputPassword4"
              placeholder="Descrição"
              v-model="category.description"
            />
          </div>
          <div class="form-group col-md">
            <label for="inputEmail5">Status</label>
            <select
              name="inputEmail5"
              id="inputEmail5"
              class="form-control"
              v-model="category.status"
            >
              <option :value="true">Ativo</option>
              <option :value="false">Inativo</option>
            </select>
          </div>
        </div>
        <div class="text-center p-3" v-if="msgError" id="msgError">
          Verifique os dados informados.
        </div>
      </form>
      <div class="text-center">
        <button v-if="!isEdit" class="btn btn-primary" @click="createCategory">
          <div v-if="!loading"><strong>Criar</strong></div>
          <b-spinner
            variant="white"
            label="Spinning"
            v-if="loading"
          ></b-spinner>
        </button>
        <button v-if="isEdit" class="btn btn-primary" @click="updateCategory">
          <div v-if="!loading"><strong>Salvar</strong></div>
          <b-spinner
            variant="white"
            label="Spinning"
            v-if="loading"
          ></b-spinner>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import {
  createCategory,
  deleteCategory,
  getCategory,
  updateCategory,
} from "../services/Category";
export default {
  name: "CategoryView",
  components: {
    Sidebar,
    Topbar,
    Footer,
  },
  data() {
    return {
      toggle: "",
      msgError: false,
      loading: false,
      toggleBool: false,
      category: {
        name: "",
        description: "",
        status: false,
        id: "",
      },
      categorys: [],
      isEdit: false,
    };
  },
  methods: {
    toggleMenu() {
      this.toggleBool = !this.toggleBool;
      if (this.toggleBool == true) {
        this.toggle = "toggled";
      } else {
        this.toggle = "";
      }
    },

    createCategory() {
      this.loading = true;
      if (!this.category.name || !this.category.description) {
        this.msgError = true;
        this.loading = false;
      } else {
        createCategory(this.category)
          .catch(() => {
            this.msgError = true;
            this.loading = false;
          })
          .then(() => {
            this.loading = false;
            this.msgError = false;
            this.getCategorys();
            this.$bvModal.hide("modalCategory");
          });
      }
    },

    updateCategory() {
      this.loading = true;
      if (
        !this.category.name ||
        !this.category.description ||
        !this.category.id
      ) {
        this.msgError = true;
        this.loading = false;
      } else {
        updateCategory(this.category)
          .catch(() => {
            this.msgError = true;
            this.loading = false;
          })
          .then(() => {
            this.loading = false;
            this.msgError = false;
            this.getCategorys();
            this.$bvModal.hide("modalCategory");
          });
      }
    },

    deleteCategory(category) {
      deleteCategory(category)
        .catch(() => {
          this.msgError = true;
          this.loading = false;
        })
        .then(() => {
          this.loading = false;
          this.msgError = false;
          this.getCategorys();
        });
    },

    openModalEdit(category) {
      this.isEdit = true;
      this.category = category;
    },

    openModalNew() {
      this.isEdit = false;
      this.category = {
        name: "",
        description: "",
        status: false,
      };
    },

    async getCategorys() {
      let categorys = await getCategory();
      this.categorys = categorys.data.data;
    },
  },
  mounted() {
    this.getCategorys();
  },
};
</script>

<style scoped>
#msgError {
  color: red;
  font-weight: bold;
}

.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  vertical-align: middle;
  text-align: center;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-align: center;
}

.table tbody tr {
  border-bottom: 1px solid #f6f6f6;
}

body {
  overflow-x: hidden;
  background-color: #ecf0fa;
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #3d405b;
  color: white;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

.caret {
  margin-right: 10px;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

.dashboard {
  padding: 30px !important;
}

.card {
  margin-bottom: 30px;
  border: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  border: none !important;
}

.card .card-body .media-body {
  text-align: left;
}

.card .media i {
  font-size: 60px;
}

@media (max-width: 768px) {
  .dashboard {
    padding: 20px !important;
  }

  .card {
    margin-bottom: 15px;
  }
}
</style>
