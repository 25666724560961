const axios = require("axios");
const baseUrl = require("../../global");
const { getToken } = require("./localStorageFunc");
import { config } from "./configHeader";

export async function createProduct(product) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.post(`${baseUrl.default}/product`, product, config);
}

export async function getProduct() {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.get(`${baseUrl.default}/product`, config);
}

export async function updateProduct(product) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.put(`${baseUrl.default}/product`, product, config);
}

export async function deleteProduct(product) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.delete(`${baseUrl.default}/product/${product.id}`, config);
}
