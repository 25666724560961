<template>
  <div>
    <div class="content">
      <div id="cookie" class="banner-cookie" v-if="cookie">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="content">
                <div style="line-height: 1.2em; font-size: 0.9em">
                  Nosso site utiliza cookies para te proporcionar uma melhor
                  experiência. Ao acessar o site, você concorda com a nossa
                  Política de Privacidade e Cookies
                </div>
                <span class="cookie-bar-btn" @click="cookie = false">
                  <span
                    class="btn btn-sm btn-verify-cookie-accepted"
                    style="
                      padding: 5px 20px;
                      margin-left: 20px;
                      background: #2b4c7e;
                      color: #ffffff;
                    "
                    >Entendi</span
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--MENU-->
      <nav
        class="navbar navbar-expand-lg fixed-top d-none d-md-block d-lg-block"
      >
        <div class="container">
          <div class="navbar-brand">
            <a class="Logo img-hover">
              <img
                src="../assets/Qual Cardapio.webp"
                alt="Logo"
                style=""
                class="logoSite d-inline-block align-text-top"
                loading="lazy"
              />
            </a>
          </div>
          <div
            class="collapse navbar-collapse d-lg-flex justify-content-center d-none"
            id="navbarNav"
          ></div>
          <div class="areaBotaoBandeira">
            <a
              class="btn btn-lg btn btn-default btn-rounded"
              target="_self"
              style="background: #198754 !important; color: #ffffff !important"
              href="https://api.whatsapp.com/send?phone=5511943885385"
            >
              <i class="fab fa-whatsapp" aria-hidden="true"></i> Experimente
              Agora!
            </a>
          </div>
        </div>
      </nav>

      <nav
        class="navbar fixed-top d-flex d-lg-none d-md-none navbar-menu align-items-center justify-content-between"
      >
        <div class="container-fluid">
          <a href="#">
            <img
              src="../assets/Qual Cardapio.webp"
              alt="Logo"
              style=""
              class="logoSite d-inline-block align-text-top"
              loading="lazy"
            />
          </a>
          <div class="d-flex">
            <button
              class="navbar-toggler ml-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarToggleExternalContent"
              aria-controls="navbarToggleExternalContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i class="fa-solid fa-bars"></i>
            </button>
          </div>
        </div>
        <div class="w-100 collapse" id="navbarToggleExternalContent">
          <div class="p-4">
            <ul class="navbar-nav nav-side">
              <li class="nav-item">
                <a
                  class="nav-link"
                  target="_self"
                  href="https://api.whatsapp.com/send?phone=5511943885385"
                  >Fale Conosco</a
                >
              </li>
            </ul>
            <a
              class="btn btn-lg btn btn-default btn-rounded w-100"
              style="background: #198754 !important; color: #ffffff !important"
              target="_self"
              href="https://api.whatsapp.com/send?phone=5511943885385"
            >
              <i class="fab fa-whatsapp" aria-hidden="true"></i> Experimente
              Agora!
            </a>
          </div>
        </div>
      </nav>

      <!--head-->
      <section id="apresentacaoSite">
        <div class="slideHome order-2 order-md-1 order-lg-1 order-xl-1">
          <div
            id="carouselIndex"
            class="carousel slide carousel-fade"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="img_slid slide-img">
                  <img
                    alt="... "
                    loading="eager"
                    src="https://img.freepik.com/free-photo/homem-de-vista-lateral-lendo-menu-no-restaurante_23-2150384799.jpg?iaID=42082209&qry=cardápio%20digital%20interativo%20JPG%20&oIA=landscape"
                    style="width: 100%; height: 100%; object-fit: cover"
                  />
                </div>
              </div>
              <div class="carousel-item">
                <div class="img_slid slide-img">
                  <img
                    alt="... "
                    loading="lazy"
                    src="https://img.freepik.com/free-photo/cortar-m-o-apontando-no-quadro-negro-perto-da-comida_23-2147740728.jpg?iaID=1576092&qry=cardápio%20digital%20interativo%20JPG%20&oIA=landscape"
                    style="width: 100%; height: 100%; object-fit: cover"
                  />
                </div>
              </div>
              <div class="carousel-item">
                <div class="img_slid slide-img">
                  <img
                    alt="... "
                    loading="lazy"
                    src="https://img.freepik.com/free-photo/close-up-de-homem-olhar-em-branco-tablete-digital_23-2147874415.jpg?iaID=2766170&qry=cardápio%20digital%20interativo%20JPG%20&oIA=landscape"
                    style="width: 100%; height: 100%; object-fit: cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="SobreSlide order-1 order-md-2 order-lg-2 order-xl-2 d-flex justify-content-center"
        >
          <div class="conteudoSobreSlide">
            <div
              id="ctl00_ControlPaginaInformativa_SobreVideo_conteudo"
              class="container_conteudo_informativo"
            >
              <div class="">
                <div class="ia-titulo-002" style="text-align: center">
                  Cardápio Digital Inovador!
                </div>

                <div class="ia-texto-001">
                  Transforme a experiência dos seus clientes com o nosso<br />
                  aplicativo móvel personalizável, elevando seu restaurante à
                  era digital interativa!
                </div>
              </div>
            </div>
          </div>
          <div class="conteudoFormulario">
            <div class="form-info container">
              <div class="campRigth">
                <div class="row">
                  <div class="col-md-12">
                    <div class="topo_conteudo_formulario">
                      <div>Transforme seu menu: inove e encante!</div>
                      <div
                        style="
                          font-size: 13px;
                          text-align: center;
                          margin-top: 5px;
                        "
                      >
                        Transforme seu menu em uma experiência única! Preencha e
                        eleve seu negócio ao próximo nível
                      </div>
                    </div>

                    <div class="container_formulario"></div>

                    <div id="ctl00_ControlFormulario_upFormulario">
                      <div class="container_formulario_campos row">
                        <div class="item_formulario col-md-12">
                          <div
                            data-type="0"
                            data-id="4106"
                            data-obrigatorio="1"
                            class="form-group"
                          >
                            <label>Nome*</label
                            ><input type="text" class="form-control" />
                          </div>
                          <span
                            id="ctl00_ControlFormulario_rptCamposFormulario_ctl00_C2FormDynamicField1"
                          ></span>
                        </div>

                        <div class="item_formulario col-md-12">
                          <div
                            data-type="9"
                            data-id="4107"
                            data-obrigatorio="1"
                            class="form-group"
                          >
                            <label>E-mail*</label
                            ><input type="text" class="form-control" />
                          </div>
                          <span
                            id="ctl00_ControlFormulario_rptCamposFormulario_ctl01_C2FormDynamicField1"
                          ></span>
                        </div>

                        <div class="item_formulario col-md-12">
                          <div
                            data-type="0"
                            data-id="4108"
                            data-obrigatorio="0"
                            class="form-group"
                          >
                            <label>Mensagem</label
                            ><textarea rows="2" cols="20" class="form-control">
                            </textarea>
                          </div>
                          <span
                            id="ctl00_ControlFormulario_rptCamposFormulario_ctl02_C2FormDynamicField1"
                          ></span>
                        </div>
                      </div>
                      <div style="text-align: center; margin-top: 10%">
                        <a
                          type="button"
                          class="btn btn-lg btn btn-default btn-rounded w-100"
                          target="_self"
                          href="https://api.whatsapp.com/send?phone=5511943885385"
                          style="
                            margin-top: 5px !important;
                            background: #198754 !important;
                            color: #ffffff !important;
                          "
                        >
                          <i class="fa fa-envelope-o" aria-hidden="true"></i
                          >&nbsp;<span
                            id="ctl00_ControlFormulario_lblTextoBotao"
                            >Experimente Agora!</span
                          >
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="retangulo"></div>
          </div>
        </div>
      </section>

      <!--A EMPRESA-->
      <section
        data-type="4"
        style="
          background-size: cover;
          background-attachment: fixed;
          color: #000000;
          background: #fdfdfd;
          padding-top: 60px;
          padding-bottom: 60px;
        "
      >
        <div class="container">
          <div class="row">
            <div
              class="col-md-12 TextoConteudoEditavel"
              style="display: none"
            ></div>
            <div class="col-md-12" style="min-height: 0">
              <!-- CONTEUDO INFORMATIVO -->
              <div
                id="ctl00_ContentPlaceHolderCorpo_SuperContainer_B4_rptContainers_ctl00_ControlPaginaInformativa_conteudo"
                class="container_conteudo_informativo"
                style="position: relative; max-width: 100%"
              >
                <div class="btgrid">
                  <div class="row row-1 ia-alinhamento-row-vertical">
                    <div class="col col-md-5">
                      <div class="content">
                        <p>
                          <img
                            src="https://img.freepik.com/free-photo/feche-as-m-os-tirando-fotos-com-o-telefone_23-2149250114.jpg?iaID=22114707&qry=cardápio digital interativo JPG&oIA=portrait"
                            style="width: 100%"
                          />
                        </p>
                      </div>
                    </div>

                    <div class="col col-md-7 ia-container-001">
                      <div class="content">
                        <h1 class="ia-titulo-003">
                          Revolucione Seu Menu com o Qualcardapio: Interativo e
                          Sob Medida!
                        </h1>

                        <p>
                          <span class="ia-texto-002"
                            >A QualCardapio não é apenas mais uma empresa de
                            cardápios digitais; é sua parceira ideal para
                            transformar a experiência de cada cliente que entra
                            no seu estabelecimento. Estamos redefinindo o padrão
                            de interatividade e personalização em cardápios
                            digitais. Nossa solução é a ferramenta perfeita para
                            colocar seu restaurante, bar ou café na vanguarda da
                            inovação. Nossa missão é clara: expandir o alcance
                            do seu negócio, atraindo um público mais amplo e
                            conectado. Ao escolher a QualCardapio, você oferece
                            aos seus clientes um menu interativo e
                            personalizado, abrindo portas para uma experiência
                            culinária memorável. Junte-se a nós e deixe que a
                            QualCardapio seja o ingrediente secreto para o
                            sucesso do seu estabelecimento. Vamos juntos
                            saborear o futuro da hospitalidade digital!</span
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!--SERVIÇOS-->
      <section>
        <div class="container">
          <div class="row">
            <div
              class="col-md-12 TextoConteudoEditavel"
              style="display: none"
            ></div>
            <div class="col-md-12" style="min-height: 0">
              <!-- CONTEUDO INFORMATIVO -->
              <div
                id="ctl00_ContentPlaceHolderCorpo_SuperContainer_B4_rptContainers_ctl02_ControlPaginaInformativa_conteudo"
                class="container_conteudo_informativo"
                style="position: relative !important; max-width: 100%"
              >
                <div class="btgrid">
                  <div class="row row-1">
                    <div class="col-12 col-md-8 col-lg-8">
                      <div class="content">
                        <p>
                          <strong
                            ><span class="ia-titulo-004"
                              >Eleve seu restaurante com nosso app customizável
                              e interativo.</span
                            ></strong
                          >
                        </p>

                        <hr class="ia-divisor-002" />
                        <p>&nbsp;</p>

                        <p style="text-align: justify">
                          Descubra a revolução do seu cardápio com o
                          QualCardapio! Nossa expertise nos permite oferecer um
                          serviço exclusivo de desenvolvimento de aplicativos
                          móveis, transformando seu menu em uma experiência
                          interativa e personalizada. Atraia mais clientes e
                          destaque-se no mercado com um cardápio digital que
                          expande o alcance do seu negócio. Inove agora com
                          QualCardapio e sirva sucesso!
                        </p>

                        <p>
                          <a class="btn ia-botoes-002" href="Contato.aspx"
                            >Saiba Mais</a
                          >
                        </p>
                      </div>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4">
                      <div class="content">
                        <p style="text-align: center">
                          <img
                            alt=" Desenvolvimento De Aplicativo Móvel"
                            class="img-thumbnail"
                            src="https://img.freepik.com/free-photo/smartphone-de-renderiza-o-3d-com-pesquisa-line-de-preenchimento-manual_107791-15837.jpg?iaID=33309533&qry=desenvolvimento de aplicativo móvel PNG&oIA=square"
                            style="width: 100%"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!--WAVE 2-->
      <section>
        <div class="container-fluid">
          <div class="row">
            <div
              class="col-md-12 TextoConteudoEditavel"
              style="display: none"
            ></div>
            <div class="col-md-12 p-0" style="min-height: 0">
              <!-- WAVE -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
                style="background-color: #0c041c"
              >
                <path
                  fill="#fdfdfd"
                  fill-opacity="1"
                  d="M0,96L1440,288L1440,320L0,320Z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </section>

      <!--DEPOIMENTOS-->
      <section>
        <div class="container">
          <div class="row">
            <div
              class="col-md-12 TextoConteudoEditavel"
              style="display: none"
            ></div>
            <div class="col-md-12" style="min-height: 0">
              <!-- CONTEUDO INFORMATIVO -->
              <div
                id="ctl00_ContentPlaceHolderCorpo_SuperContainer_B4_rptContainers_ctl04_ControlPaginaInformativa_conteudo"
                class="container_conteudo_informativo"
                style="position: relative; max-width: 100%"
              >
                <p class="ia-titulo-007 text-center">
                  Opiniões Reais, Sucesso Comprovado!
                </p>

                <p class="ia-titulo-008 text-center">&nbsp;</p>

                <div class="btgrid btfocus">
                  <div class="row row-1">
                    <div class="col-12 col-md-4 col-lg-4">
                      <div class="content ia-container-002">
                        <div class="text-center">
                          <img
                            class="ia-imagem-002"
                            src="https://img.freepik.com/free-photo/retrato-de-mulher-jovem-navegando-no-celular_23-2148679354.jpg?iaID=10264631&qry=mulher usando aplicativo no celular JPG&oIA=square"
                          />
                        </div>

                        <p class="ia-texto-004 text-center">
                          Em apenas 3 dias, a Qualcardapio revolucionou nosso
                          restaurante. O atendimento foi excepcional, e o
                          aplicativo personalizado elevou a interatividade com
                          nossos clientes. Recomendo fortemente!
                        </p>

                        <p class="ia-titulo-006 text-center">
                          <strong>Beatriz&nbsp;</strong>
                        </p>
                      </div>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4">
                      <div class="content ia-container-002">
                        <div class="text-center">
                          <img
                            class="ia-imagem-002"
                            src="https://img.freepik.com/free-photo/retrato-de-um-homem-desgastar-culos-olhar-smartphone_23-2147935774.jpg?iaID=3442418&qry=homem usando aplicativo no celular JPG&oIA=square"
                          />
                        </div>

                        <p class="ia-texto-004 text-center">
                          Desde que adotamos o cardápio digital personalizado da
                          qualcardapio, nossa interação com os clientes melhorou
                          incrivelmente. O aplicativo móvel elevou nossa marca a
                          outro nível!
                        </p>

                        <p class="ia-titulo-006 text-center">
                          <strong>Bernardo Rossi&nbsp;</strong>
                        </p>
                      </div>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4">
                      <div class="content ia-container-002">
                        <div class="text-center">
                          <img
                            class="ia-imagem-002"
                            src="https://img.freepik.com/free-photo/homem-verificando-seu-smartphone-ap-s-o-treino-de-ioga_23-2148732914.jpg?iaID=10855619&qry=homem usando aplicativo móvel JPG&oIA=square"
                          />
                        </div>

                        <p class="ia-texto-004 text-center">
                          "Desde que adotamos o cardápio digital da
                          qualcardapio, notamos um aumento significativo no
                          engajamento dos clientes. A personalização e
                          interatividade elevaram nossa experiência de serviço a
                          outro nível!" - Restaurante Sabor SP
                        </p>

                        <p class="ia-titulo-006 text-center">
                          <strong>Rodrigo Santos</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!--ÁREA DE ATUAÇÃO-->
      <section>
        <div class="container" style="padding-bottom: 5% !important">
          <div class="row">
            <div class="col-md-12" style="display: none !important"></div>
            <div class="col-md-12" style="min-height: 0">
              <!-- CONTEUDO INFORMATIVO -->
              <!-- <div id="" class="container_conteudo_informativo">
                <div class="btgrid">
                  <div
                    class="row row-1"
                    style="display: flex; align-items: center"
                  >
                    <div class="col-12 col-md-6 col-lg-6">
                      <div class="content">
                        <p class="ia-titulo-004">
                          Revolucione Seu Restaurante com Interatividade
                          Digital!
                        </p>

                        <p class="ia-texto-003">
                          Descubra a revolução culinária com o QualCardapio!
                          Nossa tecnologia de cardápio digital interativo e
                          personalizável é a chave para conectar seu restaurante
                          com a vibrante cena gastronômica paulistana. Amplie
                          seu alcance e encante clientes com uma experiência
                          inovadora. Junte-se ao movimento digital hoje!
                        </p>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-6">
                      <div class="content">
                        <div style="text-align: center">
                          <img alt="" src="mapa.png" style="width: 100%" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </section>

      <!--FAQ-->
      <section>
        <div class="container">
          <div class="row">
            <div
              class="col-md-12 TextoConteudoEditavel"
              style="display: none"
            ></div>
            <div class="col-md-12" style="min-height: 0">
              <!-- CONTEUDO INFORMATIVO -->
              <div
                id="ctl00_ContentPlaceHolderCorpo_SuperContainer_B4_rptContainers_ctl07_ControlPaginaInformativa_conteudo"
                class="container_conteudo_informativo"
                style="position: relative; max-width: 100%"
              >
                <p style="text-align: center">
                  <span style="font-size: 36px"
                    ><strong>AINDA TEM&nbsp;D&Uacute;VIDAS?</strong></span
                  >
                </p>

                <div class="row" style="font-size: 14px">
                  <body>
                    <div class="row">
                      <!-- Primeira Coluna do FAQ -->
                      <div class="col-12 col-md-6 col-lg-6">
                        <ul>
                          <li>
                            <h5>
                              Como o aplicativo móvel da Qualcardapio pode
                              ajudar meu restaurante?
                            </h5>
                            <p>
                              Nosso aplicativo personalizável oferece uma
                              experiência única para seus clientes, permitindo
                              que eles interajam com o seu cardápio de forma
                              dinâmica, aumentando a satisfação e fidelização.
                            </p>
                          </li>
                          <li>
                            <h5>
                              É possível integrar o aplicativo com meu sistema
                              atual?
                            </h5>
                            <p>
                              Sim, nossa solução foi desenvolvida pensando na
                              facilidade de integração. Converse com nossa
                              equipe técnica para um atendimento personalizado e
                              adequado às suas necessidades.
                            </p>
                          </li>
                          <li>
                            <h5>
                              Quanto tempo leva para ter o aplicativo
                              funcionando?
                            </h5>
                            <p>
                              Em apenas alguns dias, podemos ter seu aplicativo
                              rodando, dependendo da complexidade da
                              personalização que você deseja. Nosso time está
                              pronto para atender a urgência do seu negócio.
                            </p>
                          </li>
                          <li>
                            <h5>Posso atualizar o cardápio em tempo real?</h5>
                            <p>
                              Com certeza! Nossa plataforma é intuitiva e
                              permite que você faça atualizações instantâneas no
                              seu cardápio, garantindo que seus clientes sempre
                              vejam as opções mais atuais.
                            </p>
                          </li>
                          <li>
                            <h5>
                              Existe suporte técnico em caso de dúvidas ou
                              problemas?
                            </h5>
                            <p>
                              Sim, nossa equipe de suporte está pronta para
                              atendê-lo, garantindo que qualquer contratempo
                              seja resolvido rapidamente para que seu serviço
                              não seja interrompido.
                            </p>
                          </li>
                        </ul>
                      </div>
                      <!-- Segunda Coluna do FAQ -->
                      <div class="col-12 col-md-6 col-lg-6">
                        <ul>
                          <li>
                            <h5>
                              Qual é o custo para desenvolver um aplicativo com
                              a Qualcardapio?
                            </h5>
                            <p>
                              Oferecemos planos competitivos e personalizados
                              conforme as necessidades do seu negócio. Entre em
                              contato para um orçamento sem compromisso e
                              descubra como podemos ajudar a expandir seu
                              alcance.
                            </p>
                          </li>
                          <li>
                            <h5>
                              Meu aplicativo será compatível com iOS e Android?
                            </h5>
                            <p>
                              Sim, desenvolvemos aplicativos compatíveis com as
                              principais plataformas móveis para que você não
                              perca nenhum cliente, independentemente do
                              dispositivo que utilizam.
                            </p>
                          </li>
                          <li>
                            <h5>
                              Como posso acompanhar o desempenho do meu
                              aplicativo?
                            </h5>
                            <p>
                              Disponibilizamos ferramentas analíticas para que
                              você possa monitorar o desempenho do seu
                              aplicativo, entender o comportamento dos usuários
                              e tomar decisões baseadas em dados.
                            </p>
                          </li>
                          <li>
                            <h5>
                              Posso ter funcionalidades exclusivas desenvolvidas
                              para o meu aplicativo?
                            </h5>
                            <p>
                              Absolutamente! Nossa equipe de desenvolvimento
                              está pronta para criar funcionalidades sob medida
                              para que seu aplicativo se destaque no mercado.
                            </p>
                          </li>
                          <li>
                            <h5>
                              Como a Qualcardapio garante a segurança das
                              informações no aplicativo?
                            </h5>
                            <p>
                              Levamos a segurança muito a sério. Utilizamos as
                              melhores práticas e tecnologias para garantir que
                              as informações dos seus clientes estejam seguras e
                              protegidas.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </body>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- FOOTER -->
      <div style="background-color: #000">
        <footer id="fot-04" class="pt-5">
          <div class="container pb-5">
            <div class="row">
              <div class="col-12 col-md-3 col-lg-3 col-xl-3 px-4">
                <img
                  loading="lazy"
                  src="../assets/Qual Cardapio.webp"
                  alt="Logo"
                  class="logo_rodape"
                  style="width: 100%"
                />
              </div>
              <div class="col-12 col-sm-12 col-md-6 px-4">
                <h5 class="footer-title">Sobre</h5>
                <div
                  id="ctl00_ControlPaginaInformativa_conteudo"
                  class="container_conteudo_informativo"
                  style="position: relative; max-width: 100%"
                >
                  <p>
                    Descubra a revolução em cardápios digitais com a
                    QualCardapio! Personalize sua experiência culinária o com
                    nosso aplicativo interativo e exclusivo. Amplie seu paladar
                    conosco.
                  </p>
                </div>
              </div>
              <div class="col-12 col-md-3 col-lg-3 col-xl-3 px-4">
                <h5 class="footer-title">Contato</h5>
                <p>
                  <a
                    class="emailFooter"
                    href="mailto:atendimento@grendeltecnologia.com.br"
                    >atendimento@grendeltecnologia.com.br</a
                  >
                </p>
                <p>
                  <span class="telefoneFooter">
                    <a
                      target="_blank"
                      href="https://api.whatsapp.com/send?phone=5511943885385"
                      style=""
                    >
                      11 94388-5385
                    </a>
                  </span>
                </p>
                <div class="fot-04-social flex-wrap d-flex">
                  <div class="redeSocial"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="container-fluid d-flex justify-content-center align-items-center"
          >
            <div class="copyright py-3 text-center">
              © {{ year }} Qual Cardapio. Direitos reservados.<br />
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LP",
  data() {
    return {
      loading: false,
      msgError: "",
      year: new Date().getFullYear(),
      isPayment: false,
      paymentSuccess: false,
      cookie: true,
      qrcode: {
        qrcode: {
          imagemBase64: "",
        },
      },
      newUser: {
        company: {
          url_logo: "",
          url_backgound: "",
          name: "",
          name_url: "",
          description: "",
          site: "",
          phone: 0,
          address: {
            street: "",
            district: "",
            city: "",
            state: "",
            number: 0,
          },
          cpfcnpj: "",
        },
        user: {
          name: "",
          email: "",
          islogged: false,
          password: "",
        },
      },
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500;700;900&amp;family=Roboto:wght@100&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin+Condensed:wght@400;500;600;700&amp;family=Montserrat:wght@700;800&amp;display=swap");

:root {
  --menu-text: #fff;
  --menu-fundo: rgb(0 0 0 / 33%);
  --formulario-CorDeTexto: #fff;
  --formulario-CorDeFundo: #fff0;
  --footer-fundo: #0c041c;
  --footer-texto: #ffffff;
  --retangulo: #0c041c;
}

.btgrid {
  font-size: medium !important;
}

/* ############## TÍTULOS ##############*/

@media all {
  .ia-titulo-001 {
    font-family: "Montserrat", Sans-serif;
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    color: #f6f6ef !important;
  }

  .ia-titulo-002 {
    font-family: "Montserrat", Sans-serif;
    font-size: 50px;
    line-height: 52px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  .ia-titulo-003 {
    margin-top: 0px;
    font-weight: bold;
    color: #0c041c !important;
    font-size: 36px;
  }

  .ia-titulo-004 {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 40px;
    line-height: 40px;
    font-weight: 900;
    text-align: center;
    letter-spacing: 2px;
  }

  .ia-titulo-005 {
    text-align: center;
    font-weight: 300;
    font-size: 17px;
    line-height: 17px;
    margin-top: 5px;
    margin-bottom: 25px;
  }

  .ia-titulo-006 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .ia-titulo-007 {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 36px;
    line-height: 36px;
    font-weight: 900;
    text-align: center;
    letter-spacing: 1px;
  }

  .ia-titulo-008 {
    text-align: center;
    font-weight: 300;
    font-size: 15px;
    line-height: 15px;
    margin-top: 5px;
    margin-bottom: 25px;
  }

  .ia-titulo-009 {
    color: var(--ia-cor-clara);
    font-family: "Cabin Condensed", Sans-serif;
    font-size: 28px;
    line-height: 31px;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 1px 1px #101010;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .ia-titulo-010 {
    color: #ffffff;
    font-size: 32px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 32px;
    font-family: "Montserrat", Sans-serif;
    background-color: #f6f6ef !important;
    width: auto;
    display: inline-block;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 20px;
  }

  .ia-titulo-011 {
    font-family: "Montserrat", Sans-serif;
    font-size: 60px;
    line-height: 65px;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 1px 1px #101010;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .ia-titulo-012 {
    font-family: "Cabin Condensed", Sans-serif;
    font-size: 18px;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: 1px;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .ia-titulo-013 {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 40px;
    line-height: 40px;
    font-weight: 900;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 1201px) {
}

@media only screen and (max-width: 1025px) {
  .ia-titulo-001 {
    font-size: 18px;
    line-height: 20px;
  }

  .ia-titulo-002 {
    font-size: 37px;
    line-height: 40px;
  }

  .ia-titulo-009 {
    font-size: 20px;
    line-height: 22px;
    max-width: 90%;
    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .ia-titulo-010 {
    font-size: 18px;
    line-height: 22px;
    margin: 0 auto;
    display: table;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ia-titulo-011 {
    font-size: 38px;
    line-height: 40px;
    text-align: center;
  }

  .ia-titulo-012 {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    max-width: 87%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 481px) {
}

/* ############## TEXTOS ################*/

@media all {
  .ia-texto-001 {
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;
    margin-top: 10px;
  }

  .ia-texto-002 {
    color: #0c041c !important;
    letter-spacing: 1px;
    font-size: 15px;
    line-height: 1.5em;
    font-weight: 300;
  }

  .ia-texto-003 {
    font-weight: 300;
    text-align: center;
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 1px;
  }

  .ia-texto-004 {
    text-align: center;
    font-size: 14px;
    padding-bottom: 15px;
    padding-top: 6px;
  }
}

@media only screen and (max-width: 1201px) {
}

@media only screen and (max-width: 1025px) {
  .ia-texto-001 {
    font-size: 13px;
    line-height: 15px;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 481px) {
}

/*############## DIVISOR ##############*/

@media all {
  .ia-divisor-001 {
    width: 155px;
    margin: 0 auto;
    border: 0px;
    border-top: 10px solid var(--ia-cor2);
  }

  .ia-divisor-002 {
    border: 7px solid var(--ia-cor-clara);
    width: 20%;
    float: left;
  }
}

@media only screen and (max-width: 1201px) {
}

@media only screen and (max-width: 1025px) {
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 481px) {
}

/* ################# BORDAS ################# */

@media all {
}

@media only screen and (max-width: 1201px) {
}

@media only screen and (max-width: 1025px) {
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 481px) {
}

/* ################# CONTAINERS ################# */

@media all {
  .ia-container-001 {
    background-color: #fff;
    border: 7px solid var(--ia-cor-clara);
    padding: 77px 50px;
    transform: translateX(-67px);
  }

  .ia-container-002 {
    padding: 15px;
    background: #f1f1f1;
    border-radius: 0 20px 0 20px;
    border-bottom: 3px solid #f6f6ef !important;
    margin: 10px;
    color: #474747;
  }

  .ia-container-003 {
    border-radius: 10px;
    margin: 20px 0px;
    border: 1px solid rgba(238, 238, 238, 0.74);
    box-shadow: 0 0 6px #eee;
    padding: 20px 20px;
    transition: 0.5s ease all;
  }

  .ia-container-003:hover {
    box-shadow: 0px 10px 13px #ccc;
    border: 1px solid #c4c4c4;
    transition: 0.4s;
    transform: scale(1.05);
  }
}

@media only screen and (max-width: 1201px) {
}

@media only screen and (max-width: 1025px) {
}

@media only screen and (max-width: 767px) {
  .ia-container-001 {
    padding: 38px 20px;
    transform: translateY(-67px);
  }
}

@media only screen and (max-width: 481px) {
}

/* #################  BOTÕES ################# */

@media all {
  .ia-botoes-001 {
    height: 50px;
    margin-top: 15px;
  }

  .ia-botoes-001 a {
    text-align: center;
    color: #fff;
    background: #f6f6ef !important;
    border-radius: 20px;
    padding: 10px 30px;
    font-weight: 100;
    text-transform: uppercase;
    transition: 0.5s;
    border: 2px solid #fff;
    text-decoration: none;
  }

  .ia-botoes-001 a:hover {
    color: #f6f6ef !important;
    background: #fff;
    border: 2px solid #f6f6ef !important;
  }

  .ia-botoes-002 {
    background: none;
    border: 2px solid #0c041c !important;
    color: #0c041c !important;
    font-weight: bold;
    padding: 10px 20px;
  }

  .ia-botoes-003 {
    height: 50px;
    margin-top: 30px;
  }

  .ia-botoes-003 a {
    text-align: center;
    color: #fff;
    background: #f6f6ef !important;
    border-radius: 20px;
    padding: 10px 30px;
    font-weight: 100;
    text-transform: uppercase;
    transition: 0.5s;
    border: 2px solid #fff;
    text-decoration: none;
  }

  .ia-botoes-003 a:hover {
    color: #f6f6ef !important;
    background: #fff;
    border: 2px solid #f6f6ef !important;
  }
}

@media only screen and (max-width: 1201px) {
}

@media only screen and (max-width: 1025px) {
  .ia-botoes-003 {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 481px) {
}

/* ################# IMAGENS ################# */

@media all {
  .ia-imagem-001 {
    height: 230px;
    width: 230px;
    margin-top: 30px !important;
    margin-bottom: 10px !important;
    -webkit-transition: all 0.3s ease;
    /* Safari and Chrome */
    -moz-transition: all 0.3s ease;
    /* Firefox */
    -o-transition: all 0.3s ease;
    /* IE 9 */
    -ms-transition: all 0.3s ease;
    /* Opera */
    transition: all 0.3s ease;
  }

  .ia-imagem-001:hover {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.1) rotate(10deg);
    /* Safari and Chrome */
    -moz-transform: scale(1.1) rotate(10deg);
    /* Firefox */
    -ms-transform: scale(1.1) rotate(10deg);
    /* IE 9 */
    -o-transform: translatZ(0) scale(1.1) rotate(10deg);
    /* Opera */
    transform: translatZ(0) scale(1.1) rotate(10deg);
    top: 0;
  }

  .ia-imagem-002 {
    width: 83px;
    height: 90px;
    border-bottom: 4px solid #f6f6ef !important;
    border-radius: 50%;
    margin: 15px auto;
    border: 1px solid #f6f6ef !important;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 1201px) {
}

@media only screen and (max-width: 1025px) {
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 481px) {
}

/* ################# ALINHAMENTO ################# */

@media all {
  .ia-alinhamento-row-vertical {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 1025px) {
}

@media only screen and (max-width: 767px) {
  .ia-alinhamento-row-vertical {
    display: inline-block !important;
    padding: 15px;
  }
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 481px) {
}

@import url("https://fonts.googleapis.com/css?family=Red+Hat+Display:400,500,700,900&display=swap");

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #575663;
}

.cke {
  z-index: 9998 !important;
}

.cke_dialog {
  z-index: 10010;
}

body,
.navbar.fixed-top {
  font-family: Red hat Display, sans-serif !important;
  font-size: 13px;
}

.botao_edicao_site_geral i {
  transition: 0.3s ease all;
}

jdiv,
.banner-cookie,
#whats_flutuante,
.btnZap {
  z-index: 99999 !important;
}

.offcanvas {
  width: 50% !important;
}

.bg-shadow {
  -webkit-box-shadow: 0px 6px 13px -5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 6px 13px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 13px -5px rgba(0, 0, 0, 0.1);
  border: 1px solid #f4f4f4;
}

.loading_edicao_site_geral {
  display: none;
}

.container_opcoes_site i.fa-toggle-off {
  color: #6c757d;
}

.container_edicao_site_geral .body-card {
  display: none;
  padding-bottom: 20px;
}

.dropdown:hover .dropdown-menu,
.dropdown-menu {
  display: none;
}

.dropdown-menu.show {
  display: block !important;
}

.titulo_categoria_layout {
  padding: 0.2rem;
  font-weight: 700;
  font-size: 14px;
}

.container_atributo_layout input {
  height: 2rem !important;
  font-size: 0.8rem;
}

.container_atributo_layout textarea {
  font-size: 0.8rem !important;
}

.choose_color_layout {
  width: 50px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.no-border-radius-left {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.CodeMirror-code {
  font-size: 12px !important;
}

section {
  border-top: none !important;
  margin: 0px !important;
}

#apresentacaoSite {
  position: relative !important;
}

.opcoes_resize_screen > span {
  width: 4rem;
}

.content_device {
  margin-top: 10px;
  display: none;
  justify-content: center;
}

.content_device .wrap {
  position: relative;
}

.content_device iframe {
  border: 1px solid #fff;
  height: 100vh;
  border-radius: 0.375rem;
  margin-bottom: 2rem;
  -webkit-box-shadow: 0px 10px 18px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 18px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 18px -11px rgba(0, 0, 0, 0.75);
}

.content_device.tablet .wrap {
  width: 785px;
}

.content_device.smartphone .wrap {
  width: 392px;
}

.loading_content_device {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: #000000c4;
  font-size: 16px;
  color: #fff;
}

.lista_redes_sociais .input-group-text {
  width: 45px;
  text-align: center;
}

.btn_contratar {
  padding: 10px 40px;
  background-color: #e64278;
  border: 1px solid #e64278;
}

.btn_contratar:hover {
  background-color: #b43660;
  border: 1px solid #b43660;
}

.form-control {
  font-size: 0.8rem;
}

.btn-close {
  cursor: pointer;
}

.modal-body {
  max-height: 80vh;
  overflow-y: auto;
  margin-bottom: 30px;
}

.dt_expiracao {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  padding: 5px;
}

@media (max-width: 991px) {
  .offcanvas {
    width: 100% !important;
  }

  .btn_contratar {
    padding: 10px;
  }

  .dt_expiracao {
    color: #ffffff;
    font-size: 9px;
    text-align: center;
    padding: 5px;
  }

  .status_exp > span > i {
    display: none;
  }

  .status_exp > span {
    font-size: 11px !important;
  }
}

html,
body {
  font-weight: 300;
  letter-spacing: 1px;
  word-spacing: 1px;
  padding: 0px !important;
}

* {
  line-height: 1.2em;
}

a {
  color: initial;
  text-decoration: none;
}

.logoSite {
  max-width: 270px;
  max-height: 80px;
  -webkit-filter: drop-shadow(1px 1px 0px #ffff);
  filter: drop-shadow(1px 1px 0px #ffff);
}

@media (max-width: 992px) {
  .logoSite {
    max-width: 210px;
  }
}

.logo_rodape {
  -webkit-filter: drop-shadow(1px 1px 0px #ffff);
  filter: drop-shadow(1px 1px 0px #ffff);
}

.dropdown-toggle::after {
  content: "";
  display: none !important;
}

.slideHome,
#apresentacaoSite {
  height: 650px;
}

#apresentacaoSite {
  overflow: hidden;
  display: flex;
}

#apresentacaoSite .carousel,
#apresentacaoSite .carousel-inner,
#apresentacaoSite .carousel-item,
#apresentacaoSite .img_slid {
  height: 100%;
  width: 100%;
}

.slideHome {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.campRigth .form-control {
  font-size: 1em;
  padding: 0.5em 1em;
  margin-top: 10px;
}

.SobreSlide {
  width: 100%;
}

.conteudoSobreSlide {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  max-width: 100%;
  overflow: hidden;
}

.navbar-toggler {
  background: rgb(0 0 0 / 14%) !important;
}

.navbar-nav > .nav-item > :first-child {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  cursor: pointer;
}

.submenu .nav-item {
  font-size: 0.9em;
  margin-left: 1rem;
}

.campRigth label {
  padding-left: 5px;
}

.campRigth label,
.verificationQuestion {
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 2px;
}

.dropdown-menu .dropdown-toggle:after {
  display: none;
}

.form-info {
  position: relative;
  left: 0vw;
  -webkit-animation: spin 2s linear;
  animation: spin 0.5s linear;
}

.nav-side .submenu {
  display: none;
  padding: 0px;
  list-style: none;
}

.nav-side .submenu li + li {
  border-top: 1px solid #f2f3f9;
}

/* {
  padding-left: 0px;
} */

.nav-side .submenu-item {
  color: var(--text-color);
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 0.9em;
  font-weight: 300;
  padding: 7px 30px 7px 30px;
  display: block;
  text-transform: none;
  max-width: 300px;
}

.nav-side .submenu-item:hover {
  background: var(--secondary-color);
  color: #fff;
}

.submenu .nav-item {
  font-size: 0.9em;
  margin-left: 1rem;
}

@-webkit-keyframes spin {
  0% {
    left: 100vw;
  }

  100% {
    left: 40vw;
  }
}

@keyframes spin {
  0% {
    left: 100vw;
  }

  100% {
    left: 40vw;
  }
}

@media (min-width: 992px) {
  .nav-item .nav-item .dropdown-menu {
    position: absolute;
    left: 100%;
    top: 0px;
  }
}

@media (max-width: 991px) {
  .SobreSlide {
    grid-template-columns: auto;
    grid-template-rows: auto;
    display: grid !important;
  }

  /*
    .conteudoSobreSlide {
        position: absolute;
        top: 65px;
    }*/

  .retangulo {
    display: none;
  }

  .form-info {
    left: 0px;
    position: initial;
  }

  .campRigth .form-control {
    font-size: 0.7em;
    padding: 0.4em 1em;
  }

  .slideHome,
  #apresentacaoSite {
    height: auto;
    position: initial;
  }

  #apresentacaoSite {
    overflow: initial;
    display: block;
  }

  .slideHome {
    max-height: 100vh;
    height: 50vh;
  }

  .conteudoSobreSlide {
    padding-top: 23vh;
    position: absolute;
    top: 0;
  }
}

/*** FOOTER ***/
.footer-title {
  font-weight: 600;
  letter-spacing: 0.05em;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  text-transform: uppercase;
}

.redeSocial {
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #fff !important;
}

.redeSocial:hover {
  color: #fff;
}

.social-twitter {
  background: #63c9eb;
}

.social-facebook {
  background: #4970bf;
}

.social-instagram {
  background: #ff6f80;
}

.social-youtube {
  background: #ff0000;
}

.social-whatsapp {
  background: #25d366;
}

.social-linkedin {
  background: #0e76a8;
}

.social-skype {
  background: #00aff0;
}

.social-googleplus {
  background: #db4a39;
}

.copyright {
  font-size: 13px;
}

.img_slid {
  background-color: #000000;
}

.retangulo {
  opacity: 0.7;
}

.img_slid > img {
  opacity: 0.2;
}

.navbar-toggler {
  color: #fff !important;
  border-color: #fff !important;
}

@media (max-width: 991px) {
  .SobreSlide {
    background-color: #0c041c;
  }
}

.logoSite {
  -webkit-filter: none !important;
  filter: none !important;
}

:root {
  --ia-cor1: #f6f6ef;
  --ia-cor2: #f8c904;
  --ia-cor3: #0c041c;
  --ia-cor4: #635b6b;
  --ia-cor-escura: #0c041c;
  --ia-cor-clara: #f6f6ef;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.btnZap {
  position: fixed;
  left: 40px;
  bottom: 40px;
  z-index: 999999999;
}

.btnZap:hover {
  cursor: pointer;
}

@keyframes whatsapp-anim {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(0.9);
  }

  40% {
    transform: scale(1.1);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.banner-cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000000;
  color: #ffffff;
  padding: 15px;
  z-index: 9999999999999;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.22);
}

@media screen and (max-width: 991px) {
  .cookie-bar-btn {
    display: block;
    margin-top: 15px;
    text-align: center;
  }
}

@media screen and (min-width: 992px) {
  .banner-cookie .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .banner-cookie .content p {
    margin-bottom: 0;
  }
}

.fot-04-social a {
  border-radius: 40px;
}

.traducao:hover .menu-linguagem {
  display: block;
}

.navbar-nav li > :first-child:not(i) {
  color: #fff !important;
  text-transform: capitalize;
  padding-left: 0px;
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  cursor: pointer;
}

.navbar {
  backdrop-filter: blur(4px);
  background: rgb(0 0 0 / 33%) !important;
}

.retangulo {
  width: 52vw;
  height: 100vh;
  background: #0c041c !important;
  border-radius: 100px;
  transform: rotate(-15deg);
  position: relative;
  left: 5vw;
  z-index: 9;
  transition: 0.5s;
}

.campRigth {
  position: absolute;
  width: 31vw;
  right: 5vw;
  top: 120px;
  color: #fff;
  z-index: 999;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
}

@media (min-width: 991px) {
  .campRigth {
    height: 510px;
    overflow-y: overlay;
    overflow-x: hidden;
    padding-right: 30px;
  }
}

.navbar-nav .dropdown-menu {
  background: rgb(0 0 0 / 33%) !important;
}

@media (max-width: 991px) {
  .campRigth {
    width: 100%;
    padding: 10px;
    position: initial;
    background: var(--retangulo);
  }
}

#fot-04 {
  background: #0c041c !important;
  color: #ffffff !important;
}

#fot-04 *:not(.redeSocial *) {
  color: var(--footer-color);
}

.footer-title + .container_conteudo_informativo,
.footer-title + p {
  font-size: 0.9em;
}

.campRigth button.btn-success {
  width: 100%;
  font-size: 1.4em;
}

.footer-title {
  margin-top: 20px;
}

.areaBotaoBandeira {
  display: flex;
  justify-content: center;
  align-items: center;
}

#ctl00_ControlPaginaInformativa_SobreVideo_conteudo {
  color: #fdfdfd;
}

.file_container {
  margin-top: 10px;
  margin-bottom: 0px;
}

.badge_file_upload {
  font-size: 80%;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  padding: 3px 7px;
  background-color: #777;
  border-radius: 3px;
  color: #fff;
}

.file_container .list-group-item .progress {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 5px;
  margin: 0px;
  display: none;
}

.count_arquivos {
  font-size: 80%;
  font-weight: bold;
  padding: 0px 10px;
  margin-bottom: 10px;
  display: block;
}

.informacao_complementar {
  margin-right: 3px;
}

@media screen and (max-width: 767px) {
  .div_upload > .btn,
  .div_upload > div > .btn {
    width: 100%;
  }
}

.modal-politicas {
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.24);
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999999;
  overflow-y: scroll;
}

.modal-politicas.show {
  display: flex !important;
}

.modal-politicas--layer {
  display: none;
  min-width: 50vw;
  max-width: 90vw;
  background: #fff;
  color: #333;
  padding: 20px;
  max-height: 90vh;
  position: relative;
  box-shadow: 0 0 12px -3px #7d7d7d;
  overflow-y: scroll;
}

.modal-politicas--close {
  position: absolute;
  top: 3px;
  right: 3px;
  background: #333;
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  cursor: pointer;
}

.modal-politicas--close i {
  margin-left: 1px;
}

#ctl00_ContentPlaceHolderCorpo_SuperContainer_B4_rptContainers_ctl06_ControlPaginaInformativa_conteudo {
  color: #ffffff;
}
</style>
