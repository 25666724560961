<template>
  <div id="wrapper" v-bind:class="toggle">
    <!-- Sidebar -->
    <Sidebar />
    <!-- /#sidebar-wrapper -->

    <!-- Page Content -->
    <!-- /#page-topbar -->
    <Topbar @toggle="toggleMenu()" />
    <!-- /#page-topbar -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0">PRODUTOS</h5>
              </div>
            </div>
            <div class="container text-right">
              <div class="p-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="openModalNew()"
                >
                  Adicionar
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th>Imagem</th>
                      <th>Nome</th>
                      <th>Preço</th>
                      <th>Desconto</th>
                      <th>Status</th>
                      <th>Opções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="products.length == 0">
                      <td colspan="5">Sem dados</td>
                    </tr>
                    <tr v-for="product of products" :key="product.id">
                      <td id="tdImg">
                        <img
                          :src="product.img"
                          alt=""
                          id="imgProduct"
                          class="w-100"
                        />
                      </td>
                      <td>{{ product.name }}</td>
                      <td>R$ {{ product.price }}</td>
                      <td>R$ {{ product.discount }}</td>
                      <td>{{ product.status ? "Ativo" : "Pausado" }}</td>
                      <td>
                        <div role="group">
                          <button
                            class="btn btn-secondary mx-1 my-1"
                            title="Editar"
                            v-b-modal.modalProduct
                            @click="openModalEdit(product)"
                          >
                            <i class="fa fa-pencil"></i>
                          </button>
                          <button
                            class="btn btn-danger mx-1 my-1"
                            title="Excluir"
                            @click="deleteProduct(product)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /#page-footer -->
    <Footer />
    <!-- /#page-footer -->
    <!-- /#page-content-wrapper -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="modalProduct"
      tabindex="-1"
      aria-labelledby="modalProductLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-body">
            <form class="form">
              <div class="row">
                <div class="col">
                  <div class="form">
                    <div class="form-group col-md">
                      <label for="inputEmail4">Nome</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputEmail4"
                        placeholder="Nome"
                        v-model="product.name"
                      />
                    </div>
                    <div class="form-group col-md">
                      <label for="inputPassword4">Descrição</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputPassword4"
                        placeholder="Descrição"
                        v-model="product.description"
                      />
                    </div>
                    <div class="form-group col-md">
                      <label for="inputEmail5">Ativo/Invativo</label>
                      <select
                        name="inputEmail5"
                        id="inputEmail5"
                        class="form-control"
                        v-model="product.status"
                      >
                        <option :value="true">Ativo</option>
                        <option :value="false">Inativo</option>
                      </select>
                    </div>
                    <div class="form-group col-md">
                      <label for="inputPassword4">Preço</label>
                      <input
                        type="number"
                        class="form-control"
                        id="inputPassword4"
                        placeholder="Preço"
                        v-model="product.price"
                      />
                    </div>
                    <div class="form-group col-md">
                      <label for="inputPassword4">Desconto</label>
                      <input
                        type="number"
                        class="form-control"
                        id="inputPassword4"
                        placeholder="Desconto"
                        v-model="product.discount"
                      />
                    </div>

                    <div class="form-group col-md">
                      <label for="inputEmail5">Categorias</label>
                      <select
                        name="inputEmail5"
                        id="inputEmail5"
                        class="form-control"
                        v-model="product.idcategory"
                      >
                        <option value="">Sem categoria</option>
                        <option
                          v-for="category of categorys"
                          :key="category.id"
                          :value="category.id"
                        >
                          {{ category.name }}
                          <strong v-if="!category.status">Pausado</strong>
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form">
                    <label for="inputEmail5">Adicionais</label>
                    <select
                      name="inputEmail5"
                      id="inputEmail5"
                      class="form-control"
                      v-model="add"
                      @change="selectAdditional(add)"
                    >
                      <option value="">Selecione</option>
                      <option
                        v-for="additional of additionals"
                        :key="additional.id"
                        :value="{ id: additional.id, name: additional.name }"
                      >
                        {{ additional.name }}
                      </option>
                    </select>

                    <div class="container p-2">
                      <div class="row">
                        <div
                          class="col"
                          v-for="(additional, index) of additionalsSelected"
                          :key="additional.id"
                        >
                          <button
                            class="btn btn-primary"
                            @click="removeAdditional(index)"
                          >
                            <strong>{{ additional.name }}</strong>
                            <i class="fa fa-trash" style="color: red"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="form">
                    <label for="inputEmail7">Imagem do produto</label>
                    <input
                      type="text"
                      class="form-control my-2"
                      id="inputEmail7"
                      placeholder="Link imagem"
                      v-model="product.img"
                    />
                    <img
                      :src="product.img"
                      alt=""
                      class="rounded mx-auto d-block"
                      id="imgProduct"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center p-3" v-if="msgError" id="msgError">
                Verifique os dados informados.
              </div>
            </form>

            <div class="text-center">
              <button
                v-if="!isEdit"
                class="btn btn-primary"
                @click="createProduct"
              >
                <div v-if="!loading"><strong>Criar</strong></div>
                <b-spinner
                  variant="white"
                  label="Spinning"
                  v-if="loading"
                ></b-spinner>
              </button>
              <button
                v-if="isEdit"
                class="btn btn-primary"
                @click="updateProduct"
              >
                <div v-if="!loading"><strong>Salvar</strong></div>
                <b-spinner
                  variant="white"
                  label="Spinning"
                  v-if="loading"
                ></b-spinner>
              </button>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modalProductLabel"
              @click="modalProductHide()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import { getAdditional } from "../services/Additional";
import { getCategory } from "../services/Category";
import {
  createProduct,
  deleteProduct,
  getProduct,
  updateProduct,
} from "../services/Product";

export default {
  name: "ProductsView",
  components: {
    Sidebar,
    Topbar,
    Footer,
  },
  data() {
    return {
      toggle: "",
      toggleBool: false,
      isEdit: false,
      msgError: false,
      loading: false,
      perPage: 10,
      fields: ["name", "price", "discount", "status"],
      currentPage: 1,
      add: "",
      product: {
        id: "",
        name: "",
        description: "",
        idcategory: "",
        img: "https://cf.shopee.com.br/file/c11e9f9b0ee4c7e3bd2aeadbda27d7b3",
        price: 0,
        discount: 0,
        status: true,
        additional: [],
      },
      categorys: [],
      additionals: [],
      additionalsSelected: [],
      products: [],
    };
  },
  methods: {
    toggleMenu() {
      this.toggleBool = !this.toggleBool;
      if (this.toggleBool == true) {
        this.toggle = "toggled";
      } else {
        this.toggle = "";
      }
    },

    async getCategorys() {
      let categorys = await getCategory();
      this.categorys = categorys.data.data;
    },

    async getProducts() {
      let products = await getProduct();
      this.products = products.data.data;
    },

    async getAdditionals() {
      let Additionals = await getAdditional();
      this.additionals = Additionals.data.data;
    },

    createProduct() {
      this.loading = true;

      if (
        !this.product.name ||
        !this.product.description ||
        !this.product.price
      ) {
        this.msgError = true;
        this.loading = false;
      } else {
        this.product.discount = +this.product.discount;
        this.product.price = +this.product.price;
        this.product.additional = this.additionalsSelected.map((e) => {
          return e.id;
        });

        createProduct(this.product)
          .catch(() => {
            this.msgError = true;
            this.loading = false;
          })
          .then(() => {
            this.loading = false;
            this.msgError = false;
            this.additionalsSelected = [];
            this.getProducts();
            this.modalProductHide();
          });
      }
    },

    updateProduct() {
      this.loading = true;
      if (
        !this.product.name ||
        !this.product.description ||
        !this.product.price ||
        !this.product.id
      ) {
        this.msgError = true;
        this.loading = false;
      } else {
        this.product.additional = this.additionalsSelected.map((e) => {
          return e.id;
        });
        this.product.discount = +this.product.discount;
        this.product.price = +this.product.price;

        updateProduct(this.product)
          .catch(() => {
            this.msgError = true;
            this.loading = false;
          })
          .then(() => {
            this.loading = false;
            this.msgError = false;
            this.getProducts();
            this.modalProductHide();
          });
      }
    },

    deleteProduct(product) {
      deleteProduct(product)
        .catch(() => {
          this.msgError = true;
          this.loading = false;
        })
        .then(() => {
          this.loading = false;
          this.msgError = false;
          this.getProducts();
        });
    },

    openModalEdit(product) {
      this.isEdit = true;
      this.product = product;
      this.additionalsSelected = [];

      for (let id of product.additional) {
        this.additionalsSelected.push(
          this.additionals.filter((e) => {
            return e.id === id;
          })[0]
        );
      }

      this.modalProductShow();
    },

    modalProductHide() {
      document.getElementById("modalProduct").style.display = "none";
      document.getElementById("modalProduct").classList.add("hide");
    },

    modalProductShow() {
      document.getElementById("modalProduct").style.display = "block";
      document.getElementById("modalProduct").classList.add("show");
    },

    openModalNew() {
      this.isEdit = false;

      this.product = {
        name: "",
        description: "",
        idcategory: "",
        img: "https://cf.shopee.com.br/file/c11e9f9b0ee4c7e3bd2aeadbda27d7b3",
        price: 0,
        discount: 0,
        status: true,
        additional: [],
      };

      this.additionalsSelected = [];
      this.modalProductShow();
    },

    selectAdditional(add) {
      this.additionalsSelected.push(add);
      this.add = "";
    },

    removeAdditional(index) {
      this.additionalsSelected.splice(index, 1);
    },
  },
  mounted() {
    this.getCategorys();
    this.getProducts();
    this.getAdditionals();
  },
  computed: {
    rows() {
      return this.products.length;
    },
  },
};
</script>

<style scoped>
#text-right {
  text-align: right !important;
}
#tdImg {
  width: 10%;
}

#imgProduct {
  border-radius: 100px;
}

#msgError {
  color: red;
  font-weight: bold;
}

#imgProduct {
  width: 85%;
}

.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  vertical-align: middle;
  text-align: center;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-align: center;
}

.table tbody tr {
  border-bottom: 1px solid #f6f6f6;
}

body {
  overflow-x: hidden;
  background-color: #ecf0fa;
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #3d405b;
  color: white;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

.caret {
  margin-right: 10px;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

.dashboard {
  padding: 30px !important;
}

.card {
  margin-bottom: 30px;
  border: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  border: none !important;
}

.card .card-body .media-body {
  text-align: left;
}

.card .media i {
  font-size: 60px;
}

@media (max-width: 768px) {
  .dashboard {
    padding: 20px !important;
  }

  .card {
    margin-bottom: 15px;
  }
}
</style>
