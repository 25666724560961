const axios = require("axios");
const baseUrl = require("../../global");

export async function getAddressByZipCodeP(CEP) {
  return await axios.get(`https://viacep.com.br/ws/${CEP}/json`);
}

export async function calculateDistance(origins, destinations) {
  return await axios.post(`${baseUrl.default}/cep`, { origins, destinations });
}
