<template>
  <div class="container">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          class="nav-link active"
          id="nav-home-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-home"
          type="button"
          role="tab"
          aria-controls="nav-home"
          aria-selected="true"
        >
          Dados empresa
        </button>
        <button
          class="nav-link"
          id="nav-profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#nav-profile"
          type="button"
          role="tab"
          aria-controls="nav-profile"
          aria-selected="false"
        >
          Entrega
        </button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div
        class="tab-pane fade show active"
        id="nav-home"
        role="tabpanel"
        aria-labelledby="nav-home-tab"
      >
        <div class="text-center p-3">
          <h2 style="color: black">Endereço/Delivery</h2>
          <!-- <b-spinner variant="primary" label="Spinning"></b-spinner> -->
        </div>

        <div class="row">
          <div class="col-md-3">
            <img
              class="border border-dark w-100"
              :src="
                company.url_logo
                  ? company.url_logo
                  : 'https://cf.shopee.com.br/file/43213152a5de22032efe0be784cf8f37'
              "
              alt=""
            />
            <div style="color: red; font-size: 10px">
              *Apenas imagens quadradas
            </div>
          </div>
          <div class="col-md">
            <div class="row">
              <div class="col-md">
                <div
                  class="col-md pt-4"
                  style="
                    align-items: start;
                    font-weight: 900;
                    font-size: medium;
                  "
                >
                  <label for="imputName">Nome</label>
                  <input
                    class="form-control"
                    id="imputName"
                    placeholder="Nome"
                    v-model="company.name"
                  />
                </div>
                <div
                  class="col-md pt-4"
                  style="
                    align-items: start;
                    font-weight: 900;
                    font-size: medium;
                  "
                >
                  <label for="Logo">Logo</label>
                  <input
                    class="form-control"
                    id="Link imagem logo"
                    placeholder="Logo"
                    v-model="company.url_logo"
                  />
                </div>
                <div
                  class="col-md pt-4"
                  style="
                    align-items: start;
                    font-weight: 900;
                    font-size: medium;
                  "
                >
                  <label for="Color">Fundo do Logo</label>
                  <input
                    type="text"
                    class="form-control"
                    id="Color"
                    v-model="company.url_backgound"
                  />
                </div>
                <div
                  class="col-md pt-4"
                  style="
                    align-items: start;
                    font-weight: 900;
                    font-size: medium;
                  "
                >
                  <label for="Color">Cor do Nome</label>
                  <input
                    type="color"
                    class="form-control"
                    id="Color"
                    v-model="company.colorName"
                  />
                </div>
              </div>

              <div class="col-md">
                <div
                  class="col-md pt-4"
                  style="
                    align-items: start;
                    font-weight: 900;
                    font-size: medium;
                  "
                >
                  <label for="inputTelefone">Telefone</label>
                  <input
                    class="form-control"
                    id="inputTelefone"
                    placeholder="Telefone"
                    v-model="company.phone"
                  />
                </div>
                <div
                  class="col-md pt-4"
                  style="
                    align-items: start;
                    font-weight: 900;
                    font-size: medium;
                  "
                >
                  <label for="inputWhatspp">Whatsapp</label>
                  <input
                    class="form-control"
                    id="inputWhatspp"
                    placeholder="Whatsapp"
                    v-model="company.whatsapp"
                  />
                </div>
                <div
                  class="col-md pt-4"
                  style="
                    align-items: start;
                    font-weight: 900;
                    font-size: medium;
                  "
                >
                  <label for="nomeNoLink">Nome no Link</label>
                  <input
                    class="form-control"
                    id="nomeNoLink"
                    v-model="company.name_url"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md">
            <div
              class="col-md pt-4"
              style="
                align-items: start;
                font-weight: 900;
                font-size: medium;
                align-items: start;
              "
            >
              <label for="CEP">CEP</label>

              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="CEP"
                  placeholder="CEP"
                  v-model="company.address.zipCode"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    @click="getEnd()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="col-md pt-4"
              style="
                align-items: start;
                font-weight: 900;
                font-size: medium;
                align-items: start;
              "
            >
              <label for="Rua">Rua</label>
              <input
                class="form-control"
                id="Rua"
                placeholder="Rua"
                v-model="company.address.street"
              />
            </div>
            <div
              class="col-md pt-4"
              style="
                align-items: start;
                font-weight: 900;
                font-size: medium;
                align-items: start;
              "
            >
              <label for="Color">Cor botão carrinho</label>
              <input
                type="color"
                class="form-control"
                id="Color"
                v-model="company.backgroundButton"
              />
            </div>
            <div
              class="col-md pt-4"
              style="
                align-items: start;
                font-weight: 900;
                font-size: medium;
                align-items: start;
              "
            >
              <label for="Color">Botão Fundo</label>
              <input
                type="color"
                class="form-control"
                id="Color"
                v-model="company.colorBotton"
              />
            </div>
          </div>

          <div class="col-md">
            <div
              class="col-md pt-4"
              style="
                align-items: start;
                font-weight: 900;
                font-size: medium;
                align-items: start;
              "
            >
              <label for="Cidade">Cidade</label>
              <input
                class="form-control"
                id="Cidade"
                placeholder="Cidade"
                v-model="company.address.city"
              />
            </div>

            <div
              class="col-md pt-4"
              style="
                align-items: start;
                font-weight: 900;
                font-size: medium;
                align-items: start;
              "
            >
              <label for="Bairro">Bairro</label>
              <input
                class="form-control"
                id="Bairro"
                placeholder="Bairro"
                v-model="company.address.district"
              />
            </div>

            <div
              class="col-md pt-4"
              style="
                align-items: start;
                font-weight: 900;
                font-size: medium;
                align-items: start;
              "
            >
              <label for="inputNumero">N°</label>
              <input
                class="form-control"
                id="inputNumero"
                placeholder="N°"
                v-model="company.address.number"
              />
            </div>

            <div class="text-right py-3">
              <button class="btn btn-success" @click="saveCompany()">
                <strong><i class="fa fa-save"></i> Salvar</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="nav-profile"
        role="tabpanel"
        aria-labelledby="nav-profile-tab"
      >
        <div class="form-row">
          <div
            class="col-md pt-4"
            style="
              align-items: start;
              font-weight: 900;
              font-size: medium;
              align-items: start;
            "
          >
            <label for="priceKM">Limite Distância*</label>
            <input
              id="priceKM"
              type="number"
              class="form-control"
              v-model="entrega.limite"
            />
          </div>

          <div
            class="col-md"
            style="
              align-items: start;
              font-weight: 900;
              font-size: medium;
              align-items: start;
            "
          >
            <label for="priceKM">Tempo de Entrega* (minutos)</label>
            <input
              id="priceKM"
              class="form-control"
              type="number"
              v-model="entrega.tempoentrega"
            />
          </div>

          <div
            class="col-md"
            style="
              align-items: start;
              font-weight: 900;
              font-size: medium;
              align-items: start;
            "
          >
            <label for="priceKM">Preço por KM*</label>
            <input
              id="priceKM"
              type="number"
              class="form-control"
              v-model="entrega.priceKM"
            />
          </div>
        </div>
        <div class="text-right py-3">
          <button class="btn btn-success" @click="saveDelivery()">
            <strong><i class="fa fa-save"></i> Salvar</strong>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCompany, updateCompany } from "../services/Company";
import {
  createDelivery,
  getDelivery,
  updateDelivery,
} from "../services/Delivery";
import { getAddressByZipCodeP } from "../services/getCEP";

export default {
  name: "CompanyComponent",
  data() {
    return {
      qtd: 3,
      acao: "Save",
      isLimit: false,
      showAlertCep: false,
      showAlertNotCep: false,
      showAlertUpdateCompany: false,
      distancia: {
        ate: 0,
        price: 0,
      },

      company: {
        name: "",
        name_url: "",
        phone: "",
        whatsapp: "",
        url_logo: "",
        url_backgound: "",
        colorName: "",
        backgroundButton: "",
        colorBotton: "",
        address: {
          zipCode: "",
          number: "",
          city: "",
          street: "",
          district: "",
        },
        status: true,
        cnpjcpf: 0,
        id: "",
      },

      entrega: {
        limite: 0,
        tempoentrega: "",
        priceKM: 0,
      },
    };
  },
  methods: {
    async saveCompany() {
      let isSave = await updateCompany(this.company);
      if (isSave.status == 201) {
        this.showAlertUpdateCompany = true;
      }
    },

    async saveDelivery() {
      let isSaveDelivery = this.entrega.id
        ? await updateDelivery(this.entrega)
        : await createDelivery(this.entrega);

      if (isSaveDelivery.status == 201) {
        this.showAlertUpdateCompany = true;
        this.getDelivery();
      }
    },

    async getEnd() {
      if (this.company.address.zipCode) {
        let address = await getAddressByZipCodeP(this.company.address.zipCode);

        if (address.status == 200) {
          if (address.data.erro) {
            this.showAlertCep = true;
          } else {
            this.company.address.city = address.data.localidade;
            this.company.address.street = address.data.logradouro;
            this.company.address.district = address.data.bairro;
          }
        } else {
          this.showAlertCep = true;
        }
      } else {
        this.showAlertNotCep = true;
      }
      return;
    },

    async getCompany() {
      let company = await getCompany();

      if (company.data) {
        this.company = company.data;
      }
    },

    async getDelivery() {
      let delivery = await getDelivery();
      if (delivery.data) {
        this.entrega = delivery.data;
      }
    },
  },
  mounted() {
    this.getCompany();
    this.getDelivery();
  },
};
</script>

<style scoped>
html {
  text-align: left !important;
}
</style>
