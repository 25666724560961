<template>
  <nav class="navbar">
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#nav-collapse"
      aria-controls="nav-collapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
      v-on:click.prevent.stop="$emit('toggle')"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
  </nav>
</template>

<script>
import { logout } from "../services/login";

export default {
  name: "TopbarComponent",
  methods: {
    logoutUser() {
      logout();
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped>
.navbar {
  background-color: #fff;
  border-bottom: 1px solid #dee4ec;
  box-shadow: 5px 7px 26px -5px #cdd4e7;
}

.navbar .nav-link {
  color: #5b6e88 !important;
}

.navbar-toggler {
  margin-left: 1%;
}
.navbar a i {
  color: #5b6e88;
  font-size: 20px;
}

@media (max-width: 768px) {
  .navbar-dark .navbar-toggler {
    border-color: transparent;
  }
}

#logout {
  color: red;
}
</style>
