<template>
  <FormLogin />
</template>

<script>
import FormLogin from "../components/Form_Login.vue";

export default {
  name: "LoginView",
  components: {
    FormLogin,
  },
};
</script>
