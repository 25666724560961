<template>
  <div>
    <div v-if="isShowPage && company.status">
      <div
        :style="
          company.url_backgound
            ? `background-image: url('${company.url_backgound}'); opacity: 0.9`
            : ''
        "
      >
        <img
          :src="
            company.url_logo
              ? company.url_logo
              : 'https://cf.shopee.com.br/file/43213152a5de22032efe0be784cf8f37'
          "
          :alt="company.name"
          class="banner img-fluid"
        />

        <div class="container text-center titulo-ofertaPrincipal">
          <div
            class="p-2"
            :style="company.colorName ? `color: ${company.colorName}` : ''"
          >
            <h1>{{ company.name }}</h1>
            <div class="col-xs-12 text-center" v-if="opended">
              <a
                href="#"
                class="label label-aberto px-3 py-1"
                data-modalatendimento="detalhesAtendimentoModal"
              >
                Aberto agora
              </a>
            </div>
            <div class="col-xs-12 text-center text-red" v-if="!opended">
              <a href="#" class="label label-fechado px-3 py-1">
                Fechado agora
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--Informaçoes da loja-->

      <div
        class="container"
        v-if="idOrderOpen"
        v-b-modal.modal-find-order
        @click="getOpenOrders(this.idOrderOpen)"
      >
        <div
          class="card m-3 titulo-ofertaPrincipal"
          style="background-color: #d8efde"
        >
          <div class="card-body">
            <strong>Acompanhar pedido</strong>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="card m-3">
          <div class="card-body">
            <div>
              Telefone - <em>{{ company.phone }}</em>
            </div>
            <div>
              <a
                class="btn-whats"
                :href="`https://api.whatsapp.com/send?phone=55${company.whatsapp}`"
                target="_blank"
                >Whatsapp - {{ company.whatsapp }}</a
              >
            </div>
            <div><strong>Horários</strong></div>
            <div>
              <em
                >{{ this.horarios[this.dayWeek()].primeiro.entrada }} -
                {{ this.horarios[this.dayWeek()].primeiro.saida }}</em
              >
            </div>
            <div v-if="this.horarios[this.dayWeek()].segundo.entrada">
              <em
                >{{ this.horarios[this.dayWeek()].segundo.entrada }} -
                {{ this.horarios[this.dayWeek()].segundo.saida }}</em
              >
            </div>

            <div>
              Endereço -
              <em
                >Rua: {{ company.address.street }},
                {{ company.address.city }} Nº {{ company.address.number }}</em
              >
            </div>
          </div>
        </div>
      </div>

      <div v-if="opended">
        <!--Titulo simples para as principais ofertas-->

        <div class="container">
          <b-alert v-model="showCreateOrder" variant="success" dismissible>
            Pedido realizado!
          </b-alert>
        </div>

        <div v-if="AllProductsFiltered.length > 0">
          <div v-if="productShow.length > 0">
            <div class="titulo-ofertaPrincipal">
              <h2>Principais Ofertas</h2>
            </div>

            <!--Área destina às ofertas principais-->

            <div class="container pb-2" id="car-destaque">
              <b-carousel id="carousel-1" :interval="3000">
                <b-carousel-slide
                  :img-src="product.img"
                  v-for="product in productShow"
                  :key="product.id + product.discount"
                >
                  <div class="preco">
                    <strong>
                      <p>
                        <del
                          >De R$
                          {{
                            (product.price + product.discount).toFixed(2)
                          }}</del
                        ><br />Por R$ {{ product.price.toFixed(2) }}
                      </p>
                    </strong>

                    <button
                      class="btn"
                      :style="`background-color: ${company.colorBotton}; border-color: ${company.colorBotton}; color: ${company.backgroundButton}`"
                      @click="itemAddToCart(product)"
                      v-b-modal.modal-1
                    >
                      Adicionar
                    </button>
                  </div>
                </b-carousel-slide>
              </b-carousel>
            </div>
          </div>

          <div class="container">
            <div class="card mb-2">
              <div class="card-body">
                <!--Titulo simples para as principais ofertas-->
                <div class="titulo-ofertaPrincipal py-3">
                  <h2>Cardápio</h2>
                </div>
                <div class="container text-uppercase menuCategorias">
                  <button
                    class="btn btn-link link"
                    @click="filterProducts(null)"
                  >
                    Tudo
                  </button>
                  <button
                    class="btn btn-link link"
                    v-for="category in allCategorys"
                    :key="category"
                    @click="filterProducts(category)"
                  >
                    {{ category }}
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-6"
                v-for="product in AllProductsFiltered"
                :key="product.id"
                v-b-modal.modal-1
                @click="itemAddToCart(product)"
              >
                <div class="infoCardapio flexClass">
                  <div class="textoCardapio">
                    <h2>{{ product.name }}</h2>
                    <p>{{ product.description }}</p>
                    <h3>A partir de R$ {{ product.price.toFixed(2) }}</h3>
                  </div>
                  <div class="imgCardapio">
                    <img :src="product.img" :alt="product.name" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="AllProductsFiltered.length == 0" class="container py-3">
          <h2><em>Sem produtos cadastrados :(</em></h2>
        </div>

        <!--BUTTON CART-->
        <ul
          class="list-group"
          style="
            position: fixed;
            bottom: 20px;
            right: 30px;
            z-index: 1;
            border-radius: 50px;
          "
          v-if="cart.length > 0"
        >
          <button
            class="btn"
            :style="`background-color: ${company.colorBotton}; border-color: ${company.colorBotton}; color: ${company.backgroundButton}`"
            v-b-modal.modal-cart-finish
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-cart"
              viewBox="0 0 16 16"
            >
              <path
                d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
              />
            </svg>
            <span class="badge badge-light">{{ cart.length }}</span>
          </button>
        </ul>
      </div>

      <!--Rodapé da página-->
      <footer>
        <p class="copyright-content">
          Copyright © {{ new Date().getFullYear() }} ZAPINFOOD todos os direitos
          reservados.
        </p>
      </footer>
    </div>

    <div class="container text-center" v-if="!isShowPage" id="spinner-init">
      <div class="spinner-border text-danger" role="status"></div>
    </div>

    <div class="container pt-5" v-if="!company.status">
      Ops! Algo deu errado.
    </div>

    <!-- Modal add cart -->
    <b-modal id="modal-1" hide-title hide-footer size="">
      <div class="col-md">
        <div class="container mb-2">
          <div class="row d-flex justify-content-between align-items-center">
            <div class="col-md">
              <img
                :src="itemAdd.product.img"
                class="img-fluid rounded"
                :alt="itemAdd.product.name"
              />
            </div>
            <div class="col-md">
              <p class="lead fw-normal mb-2">{{ itemAdd.product.name }}</p>
              <p>
                <span class="text-muted">{{
                  itemAdd.product.description
                }}</span>
              </p>
            </div>
            <div class="col-md">
              <p class="lead fw-normal mb-2">Quantidade</p>
              <input
                min="1"
                name="quantity"
                value="1"
                type="number"
                class="form-control"
                v-model="itemAdd.qtde"
              />
            </div>
            <!-- <div class="col-md py-2">
                                <h5 class="mb-0">R$ {{ (itemAdd.product.price * itemAdd.qtde) + sumAddtionals() }}</h5>
                            </div> -->
            <!-- <div class="col-md-1 col-lg-1 col-xl-1 text-end">
                                <a href="#!" class="text-danger"><i class="icofont-trash"></i></a>
                            </div> -->
          </div>

          <div
            class="d-flex justify-content-between align-items-center p-1 pt-2"
            v-if="itemAdd.product.additional.length > 0"
          >
            <p class="lead fw-normal mb-2">Adicionais</p>
          </div>

          <div class="form" v-if="itemAdd.product.additional.length">
            <select
              name="inputEmail5"
              id="inputEmail5"
              class="form-control"
              v-model="add"
              @change="selectAdditional(add)"
            >
              <option
                v-for="additional of itemAdd.product.additional"
                :key="additional.id"
                :value="additional"
              >
                {{ additional.name }} - R$ {{ additional.price }}
              </option>
            </select>

            <div class="container text-center">
              <ul class="list-inline pt-1">
                <li
                  class="list-inline-item"
                  v-for="(additional, index) of additionalsAdd"
                  :key="additional.id"
                  @click="removeAdditional(index)"
                >
                  <i class="icofont-trash" style="color: #e76d6d"></i>
                  <strong
                    >{{ additional.name }} R$ {{ additional.price }}
                  </strong>
                </li>
              </ul>
            </div>
          </div>

          <button
            type="button"
            class="btnaddcart btn-block btn-lg btn"
            :style="`background-color: ${company.colorBotton}; border-color: ${company.colorBotton}; color: ${company.backgroundButton}`"
            @click="addToCardItem()"
          >
            <strong
              >Adicionar R$
              {{
                itemAdd.product.price * itemAdd.qtde + sumAddtionals()
              }}</strong
            >
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-cart-finish" hide-title hide-footer size="xl">
      <div class="container">
        <div class="row">
          <div class="col-md-4 order-md-2 mb-4">
            <h4 class="d-flex justify-content-between align-items-center mb-3">
              <span class="text-muted">Seu carrinho</span>
              <span class="badge badge-secondary badge-pill">{{
                cart.length
              }}</span>
            </h4>
            <ul class="list-group mb-3">
              <li
                class="list-group-item d-flex justify-content-between lh-condensed"
                v-for="(item, index) of cart"
                :key="item.id"
              >
                <div>
                  <h6 class="my-0">
                    {{ item.qtde }} - {{ item.product.name }}
                  </h6>
                  <small class="text-muted">{{
                    item.product.description
                  }}</small>

                  <div v-for="add of item.additional" :key="add.id">
                    <i class="icofont-checked"></i
                    ><small class="text-muted">{{ add.name }}</small>
                  </div>
                </div>
                <span class="text-muted"
                  >R$
                  {{
                    (
                      item.product.price * item.qtde +
                      sumAddtionalsCart(item.additional, item.qtde)
                    ).toFixed(2)
                  }}</span
                >
                <span style="color: red" @click="deleteItemCart(index)"
                  ><i class="icofont-trash"></i
                ></span>
              </li>
              <!-- <li class="list-group-item d-flex justify-content-between bg-light">
                            <div class="text-success">
                                <h6 class="my-0">Código de promoção</h6>
                                <small>CODIGOEXEMEPLO</small>
                            </div>
                            <span class="text-success">-R$5</span>
                        </li> -->
              <li class="list-group-item d-flex justify-content-between">
                <span
                  >Total (BRL)<br /><small class="text-muted"
                    >Tx. Entrega R$ {{ client.distance.toFixed(2) }}</small
                  ></span
                >

                <strong
                  >R$
                  {{
                    (sumCart() + +client.distance.toFixed(2)).toFixed(2)
                  }}</strong
                >
              </li>
            </ul>

            <!-- <form class="card p-2">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Código promocional">
                            <div class="input-group-append">
                                <button type="submit" class="btn btn-secondary">Resgatar</button>
                            </div>
                        </div>
                    </form> -->
          </div>
          <div class="col-md-8 order-md-1">
            <h4 class="mb-3">Dados da entrega</h4>
            <form class="needs-validation" novalidate>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label for="primeiroNome">Nome</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nome"
                    v-model="client.name"
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <label for="sobrenome">Celular</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Celular"
                    v-model="client.phone"
                  />
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md">
                  <label for="CEP">CEP</label>

                  <div class="input-group mb-0">
                    <input
                      type="text"
                      class="form-control"
                      id="CEP"
                      placeholder="CEP"
                      v-model="client.address.zipCode"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-primary"
                        type="button"
                        @click="getEndCart()"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <label for="endereco2"
                    >Rua<span class="text-muted">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="endereco2"
                    placeholder="Rua"
                    v-model="client.address.street"
                    @change="calculateDistance()"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md">
                  <label for="endereco2"
                    >Bairro<span class="text-muted">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="endereco2"
                    placeholder="Bairro"
                    v-model="client.address.district"
                    @change="calculateDistance()"
                  />
                </div>
                <div class="col-md mb-3">
                  <label for="cep">Cidade</label>
                  <input
                    type="text"
                    class="form-control"
                    id="cep"
                    placeholder="Cidade"
                    v-model="client.address.city"
                    @change="calculateDistance()"
                  />
                </div>

                <div class="col-md mb-3">
                  <label for="cep">Nº</label>
                  <input
                    type="number"
                    class="form-control"
                    id="cep"
                    placeholder="Número"
                    v-model="client.address.number"
                    @change="calculateDistance()"
                  />
                </div>
              </div>

              <h4 class="mb-3">Pagamento</h4>

              <div class="d-flex my-3">
                <div class="custom-control custom-radio mx-2">
                  <input
                    id="credito"
                    name="paymentMethod"
                    type="radio"
                    class="custom-control-input"
                    @click="validMethodPayment(1)"
                  />
                  <label class="custom-control-label" for="credito"
                    >Cartão</label
                  >
                </div>
                <div class="custom-control custom-radio mx-2">
                  <input
                    id="debito"
                    name="paymentMethod"
                    type="radio"
                    class="custom-control-input"
                    @click="validMethodPayment(2)"
                  />
                  <label class="custom-control-label" for="debito">PIX </label>
                </div>
                <div class="custom-control custom-radio mx-2">
                  <input
                    id="paypal"
                    name="paymentMethod"
                    type="radio"
                    class="custom-control-input"
                    @click="validMethodPayment(3)"
                  />
                  <label class="custom-control-label" for="paypal"
                    >Dinheiro</label
                  >
                </div>
              </div>
              <div
                class="d-flex my-3 col-md-4"
                v-if="client.payment == 'Dinheiro'"
              >
                <input
                  type="number"
                  placeholder="Troco para?"
                  class="form-control"
                  v-model="client.thing"
                  @blur="calculateThing()"
                />
              </div>

              <button
                class="btn btn-success btn-lg btn-block"
                type="button"
                @click="createOrder()"
                v-if="cart.length > 0 && isCalculateThing"
                :disabled="isSaveOrder"
              >
                <div v-if="!isSaveOrder">Pedir</div>
                <b-spinner
                  v-if="isSaveOrder"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </button>
            </form>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-find-order" hide-title hide-footer>
      <div class="container">
        <div class="row">
          <div class="col-md mb-4">
            <h4 class="d-flex justify-content-between align-items-center mb-3">
              <span class="text-muted">Seu pedido</span>
              <span class="badge badge-light badge-pill">{{
                orderOpended.status == 1
                  ? "Aberto"
                  : orderOpended.status == 2
                  ? "Preparando"
                  : "Em entrega"
              }}</span>
            </h4>
            <ul class="list-group mb-3">
              <li
                class="list-group-item d-flex justify-content-between lh-condensed"
                v-for="(item, index) of orderOpended.itens"
                :key="item.id"
              >
                <div>
                  <h6 class="my-0">
                    {{ item.qtde }} - {{ item.product.name }}
                  </h6>
                  <small class="text-muted">{{
                    item.product.description
                  }}</small>

                  <div v-for="add of item.additional" :key="add.id">
                    <i class="icofont-checked"></i
                    ><small class="text-muted">{{ add.name }}</small>
                  </div>
                </div>
                <span class="text-muted"
                  >R$
                  {{
                    (
                      item.product.price * item.qtde +
                      sumAddtionalsCart(item.additional, item.qtde)
                    ).toFixed(2)
                  }}</span
                >
                <span style="color: red" @click="deleteItemCart(index)"
                  ><i class="icofont-trash"></i
                ></span>
              </li>
              <!-- <li class="list-group-item d-flex justify-content-between bg-light">
                            <div class="text-success">
                                <h6 class="my-0">Código de promoção</h6>
                                <small>CODIGOEXEMEPLO</small>
                            </div>
                            <span class="text-success">-R$5</span>
                        </li> -->
              <li class="list-group-item d-flex justify-content-center">
                <span
                  ><small class="text-muted"
                    >Tx. Entrega R$
                    {{ orderOpended.client.distance.toFixed(2) }}</small
                  ></span
                >
              </li>
            </ul>

            <!-- <form class="card p-2">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Código promocional">
                            <div class="input-group-append">
                                <button type="submit" class="btn btn-secondary">Resgatar</button>
                            </div>
                        </div>
                    </form> -->
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { getDatasAllCompany } from "../services/Company";
import { createOrder, getOrdersById } from "../services/Orders";
import { calculateDistance, getAddressByZipCodeP } from "../services/getCEP";

export default {
  name: "RequestsView",
  data() {
    return {
      additionalsAdd: [],
      isCalculateThing: false,
      showCreateOrder: false,
      add: "",
      client: {
        name: "",
        phone: "",
        payment: "",
        distance: 0,
        thing: 0,
        address: {
          city: "",
          street: "",
          number: 0,
          zipCode: "",
          district: "",
        },
      },
      itemAdd: {
        product: {
          additional: [],
        },
        additional: [],
        qtde: 1,
      },
      opended: false,
      isSaveOrder: false,
      allCategorys: [],
      AllProductsFiltered: [],
      productShow: [],
      horarios: {},
      company: {
        status: true,
      },
      isShowPage: false,
      cart: [],
      idOrderOpen: "",
      orderOpended: {
        client: {
          distance: 0,
        },
      },
    };
  },
  methods: {
    filterProducts(category) {
      this.AllProductsFiltered = this.allProducts.filter((product) => {
        if (category) {
          return product.idcategory == category;
        } else {
          return true;
        }
      });
      this.productShow = this.AllProductsFiltered.filter(
        (product) => product.discount > 0
      );
    },

    storeOpen(horarios) {
      let format = "HH:mm";
      let timeNow = moment().format(format);
      let time = moment(timeNow, format),
        beforeTime1 = moment(horarios.primeiro.entrada, format),
        afterTime1 = moment(horarios.primeiro.saida, format),
        beforeTime2 = moment(horarios.segundo.entrada, format),
        afterTime2 = moment(horarios.segundo.saida, format);
      let open =
        time.isBetween(beforeTime1, afterTime1) ||
        time.isBetween(beforeTime2, afterTime2);
      this.opended = open;
      return;
    },

    dayWeek() {
      const semana = [
        "domingo",
        "segunda",
        "terca",
        "quarta",
        "quinta",
        "sexta",
        "sabado",
      ];
      let d = new Date();
      return semana[d.getDay()];
    },

    itemAddToCart(item) {
      this.itemAdd.product = item;
    },

    selectAdditional(add) {
      this.additionalsAdd.push(add);
      this.add = "";
      this.sumAddtionals();
    },

    removeAdditional(index) {
      this.additionalsAdd.splice(index, 1);
      this.sumAddtionals();
    },

    sumAddtionals() {
      let sum = 0;
      for (let add of this.additionalsAdd) {
        sum += add.price;
      }
      return sum * this.itemAdd.qtde;
    },

    sumAddtionalsCart(adds, qtde) {
      let sum = 0;
      for (let add of adds) {
        sum += add.price;
      }
      return sum * qtde;
    },

    addToCardItem() {
      this.$bvModal.hide("modal-1");
      this.itemAdd.additional = this.additionalsAdd;
      this.cart.push(this.itemAdd);
      this.additionalsAdd = [];
      this.itemAdd = {
        product: {
          additional: [],
        },
        additional: [],
        qtde: 1,
      };
    },

    async getEndCart() {
      let address = await getAddressByZipCodeP(this.client.address.zipCode);
      if (address.status == 200) {
        if (!address.data.erro) {
          this.client.address.city = address.data.localidade;
          this.client.address.street = address.data.logradouro;
          this.client.address.district = address.data.bairro;
          this.calculateDistance();
        }
      }
      return;
    },

    sumCart() {
      let sum = 0;
      for (let item of this.cart) {
        sum += item.product.price * item.qtde;
        sum += this.sumAddtionalsCart(item.additional, item.qtde);
      }

      return sum;
    },

    deleteItemCart(index) {
      this.cart.splice(index, 1);
    },

    validMethodPayment(method) {
      switch (method) {
        case 1:
          this.client.payment = "Cartão";
          this.isCalculateThing = true;
          break;
        case 2:
          this.client.payment = "Pix";
          this.isCalculateThing = true;
          break;
        case 3:
          this.client.payment = "Dinheiro";
          this.isCalculateThing = false;
          break;
      }
    },

    calculateThing() {
      if (this.client.thing < this.sumCart() + this.client.distance) {
        alert("Verifique o troco informado.");
        this.client.thing = 0;
      } else {
        this.isCalculateThing = true;
      }
    },

    async calculateDistance() {
      if (this.client.address.street && this.client.address.city) {
        let distance = await calculateDistance(
          `${this.company.address.street}, ${this.company.address.city}`,
          `${this.client.address.street}, ${this.client.address.city}`
        );
        this.client.distance =
          distance.data.distance * +this.company.delivery.priceKM;
      }
      return;
    },

    async createOrder() {
      let isValidClient = true;
      this.isSaveOrder = true;

      for (let dataClient in this.client) {
        if (this.client[dataClient] == "" && dataClient !== "distance") {
          if (!dataClient === "thing") {
            isValidClient = false;
          }
        }
      }

      if (!isValidClient) {
        alert("Preencha todas as informações de entrega");
      } else {
        let isCreate = await createOrder({
          itens: this.cart,
          client: this.client,
          cpfcnpj: this.company.cpfcnpj,
        });

        if (isCreate.status == 201) {
          localStorage.setItem("idOrder", isCreate.data.data.id);
          this.showCreateOrder = true;
          this.$bvModal.hide("modal-cart-finish");
          this.idOrderOpen = localStorage.getItem("idOrder");
          this.getOpenOrders(this.idOrderOpen);
        }
      }

      this.isSaveOrder = false;
    },

    async getOpenOrders(id) {
      let orderOpended = await getOrdersById(id);
      if (orderOpended.status == 201) {
        this.orderOpended = orderOpended.data;
      }

      if (orderOpended.data.status == 3) {
        localStorage.removeItem("idOrder");
        this.idOrderOpen = null;
      }
    },
  },

  async mounted() {
    this.nameClient = this.$route.params.id;
    let allData = await getDatasAllCompany({
      nameUrl: this.nameClient,
    });

    this.horarios = allData.data.hours;

    if (allData.data) {
      this.allProducts = allData.data.products;
      this.company = allData.data.company;
      this.company.delivery = allData.data.delivery;
      this.allCategorys = new Set(
        this.allProducts.map((product) => product.idcategory)
      );
      if (this.allCategorys.length > 0) {
        this.filterProducts([...this.allCategorys][0]);
      }
    }

    this.isShowPage = true;
    this.storeOpen(this.horarios[this.dayWeek()]);

    this.idOrderOpen = localStorage.getItem("idOrder");
    if (this.idOrderOpen) {
      this.getOpenOrders(this.idOrderOpen);
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Koulen&family=Lobster+Two&family=Oswald:wght@200;300;400&family=Voltaire&display=swap");

body {
  overflow-x: hidden;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #f5f7fa;
}

.btn-custom {
  padding: 1px 15px 3px 2px;
  border-radius: 50px;
}

.btn-icon {
  padding: 8px;
  background: #ffffff;
}

.btn-whats {
  color: green;
}

.btnaddcart {
  border-radius: 50px;
}

#spinner-init {
  padding-top: 15%;
}

.copyright-content {
  font-weight: 700;
}

.menuCategorias {
  color: rgba(0, 0, 0, 0.511);
}

.link:hover {
  background-color: rgb(226, 225, 225);
}

#car-destaque {
  width: 25%;
}

@media (max-width: 768px) {
  #car-destaque {
    width: 60%;
  }
}

#cardOferta {
  width: 30%;
  border-radius: 15%;
}

.selected {
  background-color: rgb(231, 227, 227);
  color: black;
}

.card:hover {
  /* background-color: rgba(223, 221, 221, 0.483); */
  color: black;
}

.card-img-top {
  height: 40%;
  border-radius: 15% 15% 0% 0%;
}

.banner {
  margin-top: 3%;
  border-radius: 20px 20px 20px 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 15em;
}

.preco p {
  font-weight: 600;
}

footer {
  width: 100%;
  height: 100px;
  background-color: #fafafa;
  margin-top: 50px;
  text-align: center;
  color: rgb(83, 83, 83);
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titulo-ofertaPrincipal {
  font-family: "Bebas Neue", cursive;
  font-size: 1.3rem;
}

.label-aberto {
  background-color: white;
  border-radius: 50px;
  color: green;
}

.label-fechado {
  background-color: white;
  border-radius: 50px;
  color: red;
}

.lanche {
  height: 50px;
  font-family: "Bebas Neue", cursive;
  font-size: 1.7rem;
}

.infoCardapio {
  width: 100%;
  height: 100%;
  border: 0.5px solid rgba(0, 0, 0, 0.404);
}

.flexClass {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textoCardapio {
  width: 70%;
  height: 95%;
  margin-left: 15px;
}

.textoCardapio h2 {
  font-family: "Bebas Neue", cursive;
  font-weight: 200;
  font-size: 20px;
  margin-top: 10px;
}

.textoCardapio p {
  font-size: 15px;
  margin-top: 10px;
}

.textoCardapio h3 {
  font-family: "Bebas Neue", cursive;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
}

.imgCardapio {
  margin-right: 15px;
  width: 25%;
  height: 80%;
}

.imgCardapio img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
</style>
