import { createRouter, createWebHistory } from "vue-router";
import Additional from "../views/Additional.vue";
import Category from "../views/Category.vue";
import CompanyView from "../views/Company.vue";
import Dashboard from "../views/Dashboard.vue";
import HoursView from "../views/Horarios.vue";
import Landing from "../views/Landing.vue";
import Login from "../views/Login.vue";
import Products from "../views/Products.vue";
import Requests from "../views/Requests.vue";

const routes = [
  {
    path: "/",
    name: "landing-page",
    component: Landing,
  },

  {
    path: "/home",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (!token) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/menu/:id",
    name: "Menu",
    component: Requests,
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (!token) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/category",
    name: "Category",
    component: Category,
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (!token) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/additional",
    name: "Additional",
    component: Additional,
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (!token) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/company",
    name: "Company",
    component: CompanyView,
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (!token) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
  {
    path: "/hours",
    name: "Hours",
    component: HoursView,
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (!token) {
        next({ name: "Login" });
      } else {
        next();
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
