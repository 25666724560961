const axios = require("axios");
const baseUrl = require("../../global");
const { getToken } = require("./localStorageFunc");
import { config } from "./configHeader";

export async function getOrders() {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.get(`${baseUrl.default}/orders`, config);
}

export async function createOrder(order) {
  return await axios.post(`${baseUrl.default}/orders`, order, config);
}

export async function updateOrder(order) {
  const token = await getToken();
  axios.defaults.headers.common.Authorization = token;
  return await axios.put(`${baseUrl.default}/orders`, order, config);
}

export async function getOrdersById(id) {
  return await axios.get(`${baseUrl.default}/orders?idOrder=${id}`, config);
}
